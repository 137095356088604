@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 0.85rem;
  color: #333;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.logo {
    display: block;
    margin: 0 auto 30px;
}

.auth-wrapper {
    min-width: 400px;
    text-align: left;
    padding: 50px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 10px #888;
}

.auth-wrapper .btn {
    font-size: 0.85rem;
}

.auth-wrapper .btn-primary {
    background-color: #4FB3FF;
    border-color: #4FB3FF;
    width: 100%;
    text-align: center;
    outline: 0;
    padding: 0.7rem 0.75rem;
}

.auth-wrapper .btn-primary:hover {
    background-color: rgba(79, 179, 255, 0.8);
    border-color: rgba(79, 179, 255, 0.8);
}

.auth-wrapper .btn-primary:focus,
.auth-wrapper .btn-primary.focus,
.auth-wrapper .btn-primary:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.2rem rgba(79, 179, 255, 0.5);
    background-color: rgba(79, 179, 255, 0.8);
    border-color: rgba(79, 179, 255, 0.8);
}
.sidebar {
    width: 250px;
    /* min-height: 100vh; */
    height: 100%;
    background: #fff;
    box-shadow: 0px 3px 12px #0000001A;
    float: left;
    position: fixed;
    z-index: 1;
}

.logo-sidebar {
    margin: 20px auto 50px;
}

.sidebar ul {
    padding: 0;
    margin: 0 25px;
    list-style: none;
}

.sidebar ul li {
    margin-bottom: 5px;
}

.sidebar ul li:last-child {
    margin-bottom: 0;
}

.sidebar ul li a {
    font-weight: 700;
    padding: 15px 15px 15px 54px;
    display: block;
    cursor: pointer;
    border-radius: 6px;
    text-decoration: none !important;
    color: #222 !important;
    position: relative;
    text-align: left;
    transition: background 0.3s ease-out;
}

.logout-menu {
    font-weight: 700;
    padding: 15px 105px 10px 54px;
    display: block;
    cursor: pointer;
    border-radius: 6px;
    text-decoration: none !important;
    color: #222 !important;
    position: relative;
    text-align: left;
    transition: background 0.3s ease-out;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

}

.logout-menu {
    position: relative;
    bottom: -154px !important;
    left: 25px;
    right: 25px;
}

/* .sidebar ul li:hover a,
.sidebar ul .active-menu-item a {
    color: #4FB3FF;
    background: #ECF6FF;
}  */
.dashboard-menu:hover a,
.sidebar ul .dashboard-menu.active-menu-item a {
    color: #FFA43A !important;
    background: #FEF5EA !important;
}

.plant-machinery-menu:hover a,
.sidebar ul .plant-machinery-menu.active-menu-item a {
    color: #4FB3FF !important;
    background: #4fb3ff1c !important;
}

.projects-menu:hover a,
.sidebar ul .projects-menu.active-menu-item a {
    color: #847BFF !important;
    background: #847bff33;
}

.orders-menu:hover a,
.sidebar ul .orders-menu.active-menu-item a {
    color: #400080 !important;
    background: rgba(64, 0, 128, 0.1) !important;
}

.settings-menu:hover a,
.sidebar ul .settings-menu.active-menu-item a {
    color: #10b999 !important;
    background: rgba(16, 185, 153, 0.12) !important;
}

.users-menu:hover a,
.sidebar ul .users-menu.active-menu-item a {
    color: #FF98D6 !important;
    background: #FFF3FA;
}

.sidebar ul li a span,
.settings-menu span,
.logout-menu span {
    width: 30px;
    height: 31px;
    position: absolute;
    left: 11px;
    top: 10px;
    border-radius: 6px;
}

.sidebar ul li a span.active-icon,
.sidebar ul li:hover a span.inactive-icon,
.sidebar ul .active-menu-item a span.inactive-icon {
    display: none;
}

.sidebar ul .active-menu-item a span.active-icon,
.sidebar ul li:hover a span.active-icon {
    display: inline-block;
}

.dashboard-menu .active-icon {
    background: #fff url(/static/media/dashboard-active.a9543978.svg) no-repeat;
    background-position: center;
    background-size: 15px;
}

.plant-machinery-menu .active-icon {
    background: #fff url(/static/media/plant-active.3f697493.svg) no-repeat;
    background-position: center;
    background-size: 18px;
}

.consumable-register-menu .active-icon {
    background: #fff url(/static/media/consumable-active.ae2637ec.svg) no-repeat;
    background-position: center;
    background-size: 18px;
}

.projects-menu .active-icon {
    background: #fff url(/static/media/project-active.b796876c.svg) no-repeat;
    background-position: center;
    background-size: 18px;
}

.orders-menu .active-icon {
    background: #fff url(/static/media/orders-active.deda8b2e.svg) no-repeat;
    background-position: center;
    background-size: 22px;
}

.settings-menu .active-icon {
    background: #fff url(/static/media/settings-active.60e84a1a.svg) no-repeat;
    background-position: center;
    background-size: 18px;
}

.logout-menu .active-icon {
    background: #fff url(/static/media/logout-active.80b14d63.svg) no-repeat;
    background-position: center;
    background-size: 18px;
}

.dashboard-menu .inactive-icon {
    background: #F7F8FA url(/static/media/dashboard-icons-black.809ee9dc.svg) no-repeat;
    background-position: center;
    background-size: 15px;
}

.plant-machinery-menu .inactive-icon {
    background: #F7F8FA url(/static/media/plant.c708dd23.svg) no-repeat;
    background-position: center;
    background-size: 18px;
}

.projects-menu .inactive-icon {
    background: #F7F8FA url(/static/media/project.4d59b531.svg) no-repeat;
    background-position: center;
    background-size: 18px;
}

.consumable-register-menu .inactive-icon {
    background: #F7F8FA url(/static/media/consumable.81b91217.svg) no-repeat;
    background-position: center;
    background-size: 18px;
}

.orders-menu .inactive-icon {
    background: #F7F8FA url(/static/media/orders.61ec843d.svg) no-repeat;
    background-position: center;
    background-size: 22px;
}

.settings-menu .inactive-icon {
    background: #F7F8FA url(/static/media/settings.d8e59ab4.svg) no-repeat;
    background-position: center;
    background-size: 18px;
}

.logout-menu .inactive-icon {
    background: #F7F8FA url(/static/media/logout.49c27ae5.svg) no-repeat;
    background-position: center;
    background-size: 16px;
}

.users-menu .active-icon {
    background: #fff url(/static/media/users-blue.c7f66715.svg) no-repeat;
    background-position: center;
    background-size: 18px;
}

.users-menu .inactive-icon {
    background: #F7F8FA url(/static/media/users.d49f5808.svg) no-repeat;
    background-position: center;
    background-size: 15px;
}

.form-control.search {
    box-shadow: 0px 6px 12px #0000000D;
    border: 1px solid #f5f6f7;
    border-radius: 8px;
    font-size: 0.95rem;
    height: auto;
    line-height: 30px;
    padding-right: 50px;
}

.form-control.search:focus {
    border-color: #f1f2f3;
    outline: 0;
    box-shadow: 0px 6px 20px #0000000D
}

.topbar form {
    position: relative;
}

.topbar form button {
    border: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(/static/media/search.c4127766.svg) no-repeat;
    background-size: cover;
    background-position: center;
    outline: 0;
    position: absolute;
    right: 18px;
    top: 12px;
}

.search-results {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 50px;
    background: #fff;
    text-align: left;    
    box-shadow: 0px 6px 12px #0000000D;
    border: 1px solid #f5f6f7;
    border-radius: 8px;
    z-index: 999;
    padding: 15px 20px;
    max-height: 400px;
    overflow-y: auto;
}

.search-results h4 {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #8E99AF;
}

.search-results ul {
    padding: 0;
    list-style: none;
    margin: 0 0 10px;
}

.search-results ul li a {
    display: inline-block;
    line-height: 30px;
    color: #212529 !important;
    text-decoration: none;
}

.search-results ul li a:hover {
    color: #4fb3ff !important;
}

.notification-icon,
.user-avatar,
.mobile-menu-button {
    width: 45px;
    height: 45px;
    border: 1px solid #eee;
    border-radius: 50%;
    outline: 0 !important;
    transition: background 0.1s linear;
}

.notification-icon {
    background: #fff url(/static/media/notification.bf337eab.svg) no-repeat;
    background-size: 20px;
    background-position: center;
}

.notification-icon:hover {
    background: #4FB3FF url(/static/media/notification-white.e43e9c6e.svg) no-repeat;
    background-size: 20px;
    background-position: center;
}

.user-avatar {
    background: #fff url(/static/media/user.5fec04de.svg) no-repeat;
    background-size: 20px;
    background-position: center;
}

.user-avatar:hover {
    background: #4FB3FF url(/static/media/user-white.5a0e0e8e.svg) no-repeat;
    background-size: 20px;
    background-position: center;
}

.mobile-menu-button {
    background: #fff url(/static/media/menu-button.a450cc10.svg) no-repeat;
    background-size: 20px;
    background-position: center;
    display: none;
}

.mobile-menu-button:hover {
    background: #4FB3FF url(/static/media/menu-button-white.ec775fa9.svg) no-repeat;
    background-size: 20px;
    background-position: center;
}

.project-progress-charts {
    box-shadow: 0px 6px 12px #0000000D;
    border-radius: 5px;
}

.user-details-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: -6px 0px 15px #3B86FF1A;
    position: fixed;
    right: -350px;
    top: 0;
    bottom: 0;
    width: 350px;
    text-align: left;
    padding: 15px;
    z-index: 999;
    transition: 0.15s right ease-out;
}

.user-details-content.profile-sidebar-active {
    right: 0;
}

.user-details-content h5 {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0;
    color: #000000;
    font-weight: 600;
    margin-bottom: 10px;
}

.user-details-content h1 {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0;
    color: #4FB3FF;
    text-transform: uppercase;
}

.user-details-content p {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0;
    color: #7F7F7F;
    margin-bottom: 2px;
}

.user-detail h5 {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0;
    color: #000000;
    font-weight: 600;
    margin-bottom: 5px;
}

.user-detail p {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0;
    color: #7F7F7F;
    margin-bottom: 2px;
}

.user-details-content .profile-close-button {
    border: 2px solid #efefef;
    background: transparent;
    padding: 12px 16px;
    border-radius: 54px;
    float: right;
    margin-right: 15px;
    outline: 0;
}

.user-details-content button img {
    width: 12px;
    margin-bottom: 3px;
}

button:active {
    outline: 0 !important;
    box-shadow: none;
}

button:focus {
    outline: 0 !important;
}

.clear-class {
    clear: both;
}

.user-image {
    width: 65px;
    height: 65px;
    background: #ECF6FF 0% 0% no-repeat padding-box;
    border-radius: 65px;
    line-height: 65px;
    text-align: center;
    color: #4FB3FF;
    font-weight: 600;
    font-size: 26px;
    letter-spacing: 1px;
    margin: auto;
}

.user-image-edit {
    background: url(/static/media/user-profile-img.729ab248.png) no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
    border-radius: 5px;
}

.margin-zero {
    margin: 0 !important;
}

.padding-zero {
    padding: 0 !important;
}

.user-image .edit-user-pro {
    padding: 4px;
    position: absolute;
    right: 0;
    bottom: 11px;
    border-radius: 34px;
    width: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 6px #0000001F;
}

.user-image .edit-user-pro,
.user-image-edit .edit-user-pro {
    padding: 4px;
    position: absolute;
    right: 11px;
    bottom: 11px;
    border-radius: 34px;
    width: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 6px #0000001F;
}

.edit-user-pro img {
    width: 14px;
    margin-left: 3px;
    margin-bottom: 3px;
}

.basic-info-desk {
    padding: 32px 15px;
    border-bottom: 1px solid #00000021;
    width: 99%;
}

.bi {
    margin-bottom: 11px;
}

.cont-info-desk {
    padding: 32px 15px;
}

.log-out-class {
    border: 1px solid #4FB3FF !important;
    color: #4FB3FF;
    font-weight: 600;
    padding: 8px 20px !important;
    border-radius: 7px !important;
    background: #fff;
    margin-right: 10px;
}

.edit-pro-class {
    background: #4FB3FF 0% 0% no-repeat padding-box !important;
    border-radius: 10px;
    color: #fff;
    border: 1px solid #4FB3FF !important;
    color: #fff;
    font-weight: 600;
    padding: 8px 20px !important;
    border-radius: 7px !important;
    float: right;
}

.edits {
    padding-top: 20px;
    padding-left: 15px;
}

.popup_inner_user_edit {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 3%;
    margin: auto;
    border-radius: 20px;
    background: white;
}

.popup_inner_user_edit .entry-close {
    border: 1px solid #4FB3FF;
    border-radius: 5px;
    padding: 6px 16px;
}

.popup_inner_user_edit .entry-save {
    color: #fff !important;
    background: #4FB3FF 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    padding: 6px 19px !important;
    border: 1px solid #4FB3FF !important;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0;
}

.popup_inner_user_edit h5 {
    text-align: left;
    padding-top: 25px;
    letter-spacing: 0;
    color: #000000;
    opacity: 0.5;
}

.edit-f-row {
    padding: 20px 0px 39px 0;
    border-bottom: 2px solid #efefef;
}

.edit-last-row {
    padding: 15px 0px;
}

.edit-last-row input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    padding: 6px;
    margin-bottom: 15px;
}

.edit-f-row input:focus {
    outline: none;
}

.edit-f-row label {
    text-align: left;
    font: Bold 12px/15px Lato;
    letter-spacing: 0;
    color: #8E99AF !important;
    text-transform: capitalize;
    margin-top: 10px !important;
    margin-bottom: 3px !important;
}

.user-details-content-modal {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: -6px 0px 15px #3B86FF1A;
    position: absolute;
    top: 87%;
    right: 26%;
    width: 559px;
    box-shadow: 0px 10px 20px #0024471A;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    text-align: left;
    padding: 15px 15px 0 15px;
    z-index: 99;
    display: none;
}

.user-details-content-modal.notify-active {
    display: block;
}

.user-details-content-modal.notify-active .notification-icon {
    background: #4FB3FF url(/static/media/notification-white.e43e9c6e.svg) no-repeat;
    background-size: 20px;
    background-position: center;
}

.notify-clear h5 {
    float: right;
    text-align: right;
    font-size: 14px;
    letter-spacing: 0;
    color: #4FB3FF;
    text-transform: uppercase;
    font-weight: 600 !important;
    padding-bottom: 6px;
}

.notify-head h3 {
    float: left;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0;
    color: #000000;
    text-transform: uppercase;
    font-weight: 600;
}

.notification-table .row {
    margin-top: 0;
}

.table-noty td {
    vertical-align: middle;
}

.table-noty {
    margin-bottom: 0 !important;
}

.custom-table.table-noty tr td img {
    width: 28px;
}

.orange {
    color: #FFA43A;
}

.green {
    color: #10B999;
}

.blue {
    color: #10B999;
}

.grey {
    color: #8E99AF;
}

.mobile-logo {
    width: 100px;
}

.mobile-menu {
    width: 40px;
    float: left;
}

.mobile-menu-atcl {
    border-bottom: 1px solid #E4E4E4;
    background: #fff;
    padding: 20px;
}

.noti-button {
    float: right;

}

.noti-button .notification-icon {
    margin-right: 0 !important;
}

.edit-pro-head {
    text-align: left;
    font-size: 18px;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 500;
}

.User-edit .row-entry-head {
    margin-top: 20px;
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 16px;

}
button {
  cursor: pointer;
}

.login-bg form {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

label,
input {
  display: block;
  width: 100%;
}

label {
  margin-bottom: 5px;
  height: 22px;
}

ul li {
  list-style: none;
}

.gray-bg {
  background: #F7F8FA;
}

.login-bg {
  background: url(/static/media/login-bg.395954ff.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}

.full-screen {
  width: 100%;
  min-height: 100vh;
}

.vertical-center-container {
  display: flex;
  -ms-justify-content: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.overlay {
  background: rgba(0, 0, 0, 0.2);
}

.vertical-align-item {
  display: flex;
  flex-direction: column;
}

.custom-checkbox {
  display: block;
  margin-bottom: 15px;
}

.custom-checkbox input {
  padding: 0;
  height: auto;
  height: initial;
  width: auto;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom-checkbox label {
  position: relative;
  cursor: pointer;
}

.custom-checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #ddd;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.custom-checkbox input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  left: 6px;
  width: 6px;
  height: 14px;
  border: solid #4FB3FF;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.Toastify__toast {
  padding: 12px !important;
  border-radius: 3px !important;
  margin-bottom: 0;
}

.Toastify__toast-body {
  font-family: 'Lato', sans-serif !important;
  color: #fff !important;
  text-align: left !important;
}

.Toastify__toast--success {
  background: rgb(29 181 93);
}

.inner-container {
  width: calc(100% - 250px);
  min-height: 100vh;
  float: right;
  padding: 20px 40px 15px 40px;
  background: #F7F8FA;
}

.clear {
  clear: both;
}

.coming-soon {
  margin-top: 100px;
  font-size: 1.2rem;
}

.width-100 {
  width: 100%;
}

.material-card {
  background: #fff;
  box-shadow: 0px 6px 12px #0000000D;
  border-radius: 10px;
}

.back-button {
  width: 40px;
  height: 38px;
  display: block;
  background: #fff url(/static/media/arrow-back.c89d020e.svg) no-repeat;
  background-size: 16px;
  background-position: center;
  text-indent: -999999999999px;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  cursor: pointer;
  float: left;
  margin-top: 4px;
}

.back-button:hover {
  background: #4FB3FF url(/static/media/arrow-back-white.eee4200d.svg) no-repeat;
  background-size: 16px;
  background-position: center;
  border-color: #4FB3FF;
}

.back-button.projects-back:hover {
  background: #847bff url(/static/media/arrow-back-white.eee4200d.svg) no-repeat;
  background-size: 16px;
  background-position: center;
  border-color: #847bff;
}

.page-header h1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  line-height: 35px;
}

.custom-btn {
  width: auto;
  height: 35px;
  width: auto;
  padding: 0 20px;
  display: inline-block;
  background: #fff;
  border: 1px solid #4EB1FF;
  border-radius: 5px;
  color: #4FB3FF !important;
  outline: 0 !important;
  font-weight: 600;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 34px;
}

.custom-btn:hover {
  background: #4FB3FF;
  color: #fff !important;
}

.custom-btn.goto-btn {
  background: #F7F8FA url(/static/media/arrow-right.4a72a12a.svg) no-repeat;
  background-size: 14px;
  background-position: center;
  border: 0;
  box-shadow: 0 0 0;
}

.custom-btn.goto-btn:hover {
  background: #4FB3FF url(/static/media/arrow-right-white.ae1834c0.svg) no-repeat;
  background-size: 14px;
  background-position: center;
}

.custom-btn.small-btn:hover {
  background: #ff98d6 url(/static/media/arrow-right-white.ae1834c0.svg) no-repeat;
  background-size: 14px;
  background-position: center;
}

.custom-btn.goto-btn.small-btn {
  height: 30px;
  margin-top: 3px;
}

.no-items-text {
  margin-top: 100px;
}

.form-group label {
  font-weight: 600;
}

.form-control.disabled {
  background: #F2F1FF;
  border-color: #F2F1FF;
}

.activity-item-heading {
  text-align: left;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
}

.custom-table {
  background: #fff !important;
  box-shadow: 0px 6px 12px #0000000D;
  border: 1px solid #f5f6f7;
  border-radius: 10px;
}

.custom-table th {
  border-top: 0 !important;
}

.custom-table tr td {
  line-height: 25px;
  position: relative;
}

.order-type {
  text-transform: capitalize;
}

.custom-tabs {
  list-style: none;
  margin: 0 0 20px;
  padding: 5px 0 0;
  text-align: left;
  float: left;
}

.custom-tabs li {
  background: #fff;
  padding: 0 40px;
  line-height: 35px;
  margin: 0 20px 10px 0;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  outline: 0;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.custom-tabs.has-link li {
  padding: 0;
}

.custom-tabs li:hover,
.custom-tabs li.react-tabs__tab--selected,
.custom-tabs li.active-tab {
  background: #4FB3FF 0% 0% no-repeat padding-box;
  border: 1px solid #4FB3FF;
  border-radius: 5px;
  color: #fff;
}


.project-tabs.custom-tabs li:hover,
.project-tabs.custom-tabs li.react-tabs__tab--selected,
.project-tabs.custom-tabs li.active-tab {
  background: #847bff 0% 0% no-repeat padding-box;
  border: 1px solid #847bff;
  border-radius: 5px;
  color: #fff;
}

.custom-tabs li a {
  color: #8E99AF !important;
  text-decoration: none;
  display: block;
  padding: 0 20px;
  text-align: center;
}

.custom-tabs li:hover a,
.custom-tabs li.active-tab a {
  color: #fff !important;
}

.single-meta-data {
  background: #fff;
  box-shadow: 0px 6px 12px #0000000D;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 30px;
}

.single-meta-data h2 {
  font-size: 1.4rem;
  font-weight: 600;
}

.single-meta-data span {
  color: #999;
}

.project-single-meta-data span {
  color: #999;
}

.item-data-display h3 {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 25px;
  padding: 0 10px;
}

.item-data-display p {
  line-height: 25px;
  font-size: 1rem;
  padding: 0 10px;
  margin-bottom: 0;
  font-size: 16px;
}

.form-sent {
  padding: 15px 35px 0px 35px;
  text-align: left;
  font-size: 26px;
  font-weight: bold;
  padding-top: 60px;
}

.form-sent-sidebar {
  width: 325px;
  position: fixed;
  right: 10px;
  top: 0;
  min-height: 100vh;
  background: #fff;
  box-shadow: 0px 3px 12px #0000001A;
  z-index: 1;
}

.form-body {
  text-align: left;
  padding: 20px 35px;
}

.full-width {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.send-btn {
  padding: 2px 20px;
  display: inline-block;
  outline: 0 !important;
  font-weight: 500;
  box-shadow: 0px 6px 6px #0000000D;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 34px;
  transition: background 0.2s linear;
  margin-bottom: 5px;
  background: #4FB3FF 0% 0% no-repeat padding-box;
  border: 1px solid #4FB3FF;
  border-radius: 5px;
  color: #fff;
  width: 100%;
}

.add-btn {
  width: 100%;
  height: 40px;
  padding: 0 8px;
  display: inline-block;
  background: #847bff;
  border: 1px solid #847bff;
  border-radius: 8px;
  color: #fff !important;
  outline: 0 !important;
  font-weight: 500;
  box-shadow: 0px 6px 6px #0000000D;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 34px;
  transition: background 0.2s linear;
  margin-top: 10px;
}

.form-control:focus {
  box-shadow: none !important;
}

input:active {
  box-shadow: none !important;
}

.fr-loc,
.to-loc {
  background: #F2F1FF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 0;
  text-align: center;
}

.status-cell {
  background: #FEF5EA;
  border-radius: 15px;
  padding: 3px 5px;
  margin-bottom: 0;
  text-align: center;
}

.green-cell {
  background: #E8F7F3
}

.status-button {
  background: #FFF;
  border: 1px solid #4EB1FF;
  border-radius: 8px;
  color: #4EB1FF;
  padding: 1px 25px;
}

.table-activity thead th,
.table-activity tbody td {
  text-align: left;
  vertical-align: middle;
}

button:focus {
  box-shadow: none !important;
  outline: none;
}

.send-btn-a {
  text-align: center;
}

.back1 {
  margin-right: 12px;
  margin-left: 14px;
}

.add {
  margin-right: 10px;
}

.project_desc {
  text-align: left;
}

.graph {
  height: 250px;
}

.padd-common {
  padding: 50px;
}

.float-left {
  float: left;
  padding-top: 30px;
}

.table th,
.table td {
  border-top: 0;
}

.table tbody tr:last-child td {
  border-bottom: 0;
}

.table thead th {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  vertical-align: middle !important;
  padding-top: 20px;
  line-height: 16px;
  color: #8E99AF;
}

.popup_inner {
  width: 100%;
  max-width: 750px;
  margin: 15px;
  box-shadow: 0 0 50px #777;
  border-radius: 10px;
  background: #fff;
}

.track-labour .popup_inner {
  position: absolute;
  top: 0;
}

.popup_inner_large {
  max-width: 900px;
}

.popup_inner_medium {
  max-width: 600px;
}

.popup_inner_small {
  max-width: 475px;
}

.popup-heads {
  border-bottom: 1px solid #E4E4E4;
  padding: 15px 30px;
}

.material-card-detail {
  background: #fff;
  padding: 20px;
  border-top: 1px solid #E4E4E4;
  border-right: 1px solid #E4E4E4;
  border-left: 1px solid #E4E4E4;
  margin-top: 0;
  border-radius: 5px 5px 0 0;
}

.popup-sub-heading {
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.zero-padding {
  padding: 0 !important;
  border: 1px solid #E4E4E4;
}

.inventory-meta-details {
  border-radius: 5px;
  background: #fff;
  padding: 0 !important;
  box-shadow: 0px 6px 12px #0000000D;
}

.zero-margin {
  margin: 0 !important;
}

.left-bor-radius {
  border-radius: 0 0 0 5px;
}

.popup-body {
  padding: 25px 30px
}

.overflow-popup-body {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.right-bor-radius {
  border-radius: 0 0 5px 0;
}

.item-location-button:focus,
.entry-close:focus,
.labour-link:focus,
.go-back:focus,
.entry-save:focus {
  outline: 0;
}

.entry-close {
  padding: 1px 0;
}

.charts-div img {
  width: 350px;
  height: 250px;
  float: left;
}

.row-class-charts {
  margin-top: 20px;
  padding: 0 15px !important;
}

.row-class-charts img {
  width: 100% !important;
}

.line-chart {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #0000000D;
  border-radius: 8px;
  padding: 20px 15px;
}

.doughnout-chart {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #0000000D;
  border-radius: 8px;
  padding: 20px 15px;

}

.pie-chart {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #0000000D;
  border-radius: 8px;
  padding: 20px 15px;

}

.line-chart h5,
.doughnout-chart h5 {
  text-align: left;
  font: Bold 12px/15px Lato;
  letter-spacing: 0;
  color: #000000;
  text-transform: uppercase;
}

.pie-chart h4 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #10B999;
}

.pie-chart p {
  text-align: left;
  letter-spacing: 0;
  font-size: 14px;
  color: #8E99AF;
}

.user-table tr th {
  text-align: left;
}

.user-popup {
  background: #fff;
  border-radius: 6px;
  padding-top: 5px;
}

#area-chart {
  width: 445px !important;
}

#pie-chart {
  width: 300px !important;
  margin-left: 68px;
}

.menu-row,
.mob-logo-row {
  display: none;
}

.search-btn {
  display: block;
}

tbody tr td {
  width: auto;
}

.checklist-pop-up h3 {
  text-align: left;
  font-size: 15px;
}

.pop-org {
  background: #fff;
  padding: 15px 10px;
  border-radius: 8px;
}

.labour-first-row .go-back {

  margin-top: 3px;
  float: left;
}

span.required {
  color: #ED1C24
}

.not-available {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  float: left;
  padding-left: 5px;
}

.react-confirm-alert-overlay {
  background: rgb(0, 0, 0, 0.3) !important;
  opacity: 1 !important;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  -webkit-animation: none !important;
          animation: none !important;
  z-index: 999999 !important;
}

.react-confirm-alert-body {
  font-family: 'Lato', sans-serif !important;
  box-shadow: 0px 6px 12px #0000000D !important;
  text-align: center !important;
}

.react-confirm-alert-body>h1 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
}

.react-confirm-alert-button-group {
  justify-content: center !important
}

.react-confirm-alert-button-group>button {
  font-size: 13px !important;
  text-transform: uppercase;
}

.react-confirm-alert-button-group button:nth-child(1) {
  background: #ddd !important;
  color: #000 !important;
}

.react-confirm-alert-button-group button:nth-child(2) {
  background: #4FB3FF !important;
  color: #fff !important;
}

.users-main-desk {
  padding: 15px 0;
}

.consumable-link {
  padding-bottom: 5px;
  background: #e6e4ff 0% 0% no-repeat padding-box;
}

.more-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 95
}

.custom-table .more-menu {
  z-index: inherit;
  top: 15px;
}

.more-menu span {
  display: inline-block;
  width: 10px;
  height: 15px;
  background: url(/static/media/menu.4138e0f1.svg) no-repeat;
  background-position: center;
  background-size: cover;
}

.more-menu ul {
  position: absolute;
  background: #fff;
  right: 0;
  top: 16px;
  width: 75px;
  padding: 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .1), 0 1px 3px 1px rgba(60, 64, 67, .1);
  border-radius: 8px;
  text-align: center;
  border: 1px solid #eee;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: .25s ease-out 0s;
}

.more-menu:hover ul {
  z-index: 99;
  opacity: 1;
  visibility: visible;
  transition: .25s ease-out 0s;
}

.more-menu ul li {
  padding: 3px 0;
  border-bottom: 1px solid #eee;
  line-height: 20px;
  font-size: 13px;
}

.more-menu ul li:last-child {
  border-bottom: 0;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  outline: 0 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #4EB1FF !important;
  outline: 0 !important;
}

.width-50 {
  width: 50px;
}

.width-75 {
  width: 75px;
}

.width-90 {
  width: 90px;
}

#multiselectContainerReact>div {
  padding: 0.438rem 0.75rem;
  min-height: calc(1.5em + 0.75rem + 2px);
}

#multiselectContainerReact>div.optionListContainer {
  padding: 0;
}

#multiselectContainerReact>div input {
  margin-top: 0;
}

#multiselectContainerReact span,
#multiselectContainerReact .optionContainer li {
  background: #847bff !important;
  color: #fff !important;
}

.page-heading {
  text-align: left;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
}

.page-heading span {
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  color: #666;
  text-transform: none;
}

.adv-checkbox {
  text-align: left;
  padding: 5px 20px;
}

.popup_inner .adv-checkbox {
  padding: 5px 0;
}

.adv-checkbox label {
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.adv-checkbox input[type="checkbox"],
.adv-checkbox input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.adv-checkbox .custom-checkbox,
.adv-checkbox .custom-radio {
  display: inline-block;
  vertical-align: top;
  margin: 0 12px 0 0;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid #ccc;
  position: relative;
}

.adv-checkbox .custom-checkbox {
  border-radius: 2px;
  background: #10B999;
  transition: box-shadow 180ms ease;
  box-shadow: inset 0 0 0 12px #fff;
  top: 0;
}

.adv-checkbox.blue-checkbox .custom-checkbox {
  background: #847bff !important;
}

.adv-checkbox .icon-check {
  width: 12px;
  height: 12px;
  background-size: cover;
  position: absolute;
  top: 2px;
  left: 2px;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 180ms ease;
}

.adv-checkbox input[type="checkbox"]:checked+.custom-checkbox {
  border-color: #10B999;
  box-shadow: none;
}

.adv-checkbox.blue-checkbox input[type="checkbox"]:checked+.custom-checkbox {
  border-color: #847bff !important;
}

.adv-checkbox input[type="checkbox"]:checked+.custom-checkbox .icon-check {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.icon-check {
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20baseProfile%3D%22tiny%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2216%22%20height%3D%2216%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M13.483%201.992l-7.186%207.066-3.778-3.715-2.518%202.475%205.037%204.954%201.259%201.237%209.704-9.541-2.518-2.476z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  background-color: #4FB3FF !important;
}

/* media query starts here  */

@media screen and (max-width: 1199.9px) {

  .user-details-content {
    max-width: 100%;
    overflow-y: auto;
  }

  .user-image {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1024px) {

  html body .topbar-icon-wrapper {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mob-logo-row {
    text-align: left;
  }

  .mobile-menu-button {
    display: inline-block;
    margin-left: 15px;
  }

  .sidebar {
    margin-left: -250px;
    top: 82px;
    padding: 35px 0;
    height: calc(100vh - 82px);
    overflow-y: auto;
  }

  .sidebar.active-sidebar {
    margin-left: 0;
  }

  .logo-sidebar {
    display: none;
  }

  .inner-container {
    width: 100vw;
    padding: 95px 15px 30px;
    float: none;
    overflow: hidden;
  }

  .line-chart {
    box-shadow: none;
    display: block;
    margin: 0 auto;
  }

  .row-class-charts {
    padding: 0;
  }

  .search-btn {
    display: none;
  }

  .menu-row,
  .mob-logo-row {
    display: block !important;
  }

  .search-btn {
    display: none;
  }

  #area-chart {
    width: 300px !important;
    margin: 0 auto;
  }

  #pie-chart {
    width: 250px !important;
    margin-left: 0;
    margin: 0 auto;
  }

  .line-chart,
  .doughnout-chart,
  .pie-chart {
    margin: 15px;
  }

  .line-chart h5,
  .doughnout-chart h5 {
    text-align: center;
  }

  #area-chart canvas {
    width: 374 !important;
  }

  .charts-div h4,
  .charts-div p {
    text-align: center !important;
  }

  #chart-1,
  #chart-2,
  #chart-3 {
    margin: 0 auto;
  }

  .mobile-menu-atcl {
    display: block;
  }

  .topbar-mob-menu {
    background: #fff;
    padding: 15px;
    border-bottom: 2px solid #efefef;
  }

  .user-details-content-modal {
    top: 135%;
    right: 8%;
  }

  .mt-2.top-bar-main-class {
    margin: 0 !important;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9999;
  }

  .form-sent-sidebar {
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: auto;
    background: #fff;
    box-shadow: 0px 3px 12px #0000001A;
    z-index: 1;
    border-radius: 6px;
    margin-bottom: 20px;
  }

  .send-req-tabs ul {
    padding-top: 15px !important;
  }

  .popup_inner {
    max-width: 100%;
    height: 100%;
    max-height: 444px;
    overflow-y: auto;
    margin-top: 200px;
  }

  .custom-tabs-div {
    width: auto;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

  }

  .org-mem-details {
    padding: 10px;
  }

  .inventory-inner-container {
    width: 100% !important;
  }

  .super-admin-tabs {
    list-style: none;
    margin: 0 0 20px;
    padding: 5px 0 0;
    text-align: left;
    float: left;
    margin: 0;
    padding: 0;
    display: grid;
    grid-gap: 5px !important;
    grid-template-columns: repeat(6, calc(50% - 56px)) !important;
    margin-top: 5px;
  }

  .add-btn-green {
    margin-bottom: 10px;
  }

  .tab-first {
    font-size: 13px;
  }

  .more-menu {
    position: absolute;
    top: 21px;
    right: 7px;
    cursor: pointer;
    z-index: 95;
  }

  .add-labour-mem {
    max-width: 45%;
  }

  .add-labour-mem {
    max-width: 71%;
  }

  .m-inven-btn.edit-button {
    /* float: left !important; */
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .custom-form-control {
    width: 200px !important;
  }

  .send-btn {
    width: auto;
  }
}

@media screen and (max-width: 767px) {
  .auth-wrapper {
    min-width: 320px;
    padding: 25px;
    margin-top: 100px;
  }

  .vertical-center-container {
    display: flex;
    -ms-justify-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .table {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    overflow-x: auto;
  }

  .consumable-blocks {
    position: relative;
    background: #fff;
    padding: 15px;
    margin-bottom: 30px;
    border-bottom: 4px solid #847bff !important;
    border-radius: 5px;
    box-shadow: 0px 0px 3px #bbb;
    transition: 0.15s ease-in box-shadow;
    margin-top: 20px;
  }

  .comm-border {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E4E4E4;
    margin-top: 20px;
  }
}

@media screen and (max-width: 480px) {

  .user-details-content-modal {
    position: absolute;
    top: 87%;
    top: 150% !important;
    right: 0 !important;
    width: 92vw !important;
  }

  .add-labour-mem {
    max-width: 100%;
  }

  .edit-button {
    float: left !important;
    margin-top: 0 !important;
    margin-bottom: 16px !important;
  }

  .custom-tabs {
    list-style: none;
    margin: 5px 0 11px;
    padding: 5px 0 0;
    text-align: left;
    float: left;
  }

  .page-header h1 {
    font-size: 17px !important;
    line-height: 35px;
  }

  .entry-save,
  .entry-close {
    float: left;
    margin-left: 0;
    margin-right: 10px;
    margin-top: 5px;
  }

  .popup-title {
    font-size: 16px;
  }

  .users-main-desk {
    padding: 10px 0;
  }

  .consumable-blocks {
    margin-bottom: 15px;
    margin-top: 15px;

  }

  .add-btn {
    margin-bottom: 15px;
  }

  .checklist-weeks,
  .checklist-month {
    float: left;
    margin-left: 0 !important;
    margin-top: 10px;
    margin-right: 10px;
  }

  .org-mem-page-link .prev {

    padding-left: 41px;
    font-size: 14px;
  }

  .prev::before {
    width: 25px;
    height: 25px;
    content: " ";
    background: url(/static/media/arrow.f9e2888c.svg) no-repeat;
    background-size: 25px 22px;
    position: absolute;
    left: 11px;
  }

  .org-mem-page-link .next {
    padding-right: 45px;
    font-size: 14px;
  }

  .next::before {
    width: 22px;
    height: 22px;
    content: " ";
    background: url(/static/media/right-arrow.1b53d6d9.svg) no-repeat;
    background-size: 20px 19px;
    position: absolute;
    right: 14px;
  }

  .consumable-blocks .consumable-link-btn.consumable-arrow {
    position: absolute;
    bottom: 24px;
    right: 14px;
    top: auto;
  }

  .consumable-blocks .consumable-link-btn {
    position: absolute;
    width: auto;
    top: -4px;
    right: 0;
  }

  .right-float-edits img,
  .right-float-edits button {
    float: right;
  }

  .super-admin-tabs {
    grid-gap: 5px !important;
    grid-template-columns: repeat(6, calc(50% - 41px)) !important;
    margin-top: 5px;
  }

  .user-image-edit {
    height: 200px;
    width: 200px;
  }

  .custom-table {
    border-radius: 0;
  }

  .row-class-charts {
    margin-top: 0;
  }

  .custom-tabs li {
    margin: 0 10px 10px 0;
  }

  .consumable-link {
    margin-right: 15px;
  }

  .custom-table tr td img {
    width: 12px;
  }

  .add-labour-mem {
    max-width: 80%;
  }

  .green-save {
    margin-left: 0 !important;
  }

  .edit-button {
    margin-top: 15px !important;
  }

  .item-data-display h3 {
    text-align: center;
  }

  .item-data-display p {
    text-align: center;
  }

  .order-item-name {
    text-align: left;
  }
}

@media screen and (max-width: 360px) {
  .auth-wrapper {
    min-width: 313px;
    margin-top: 100px;
  }

  .page-header h1 {
    font-size: 12px !important;
    line-height: 35px;
  }

}

.month-select .react-datepicker__header,
.month-select .react-datepicker__navigation {
  display: none !important;
}

.month-select .react-datepicker-wrapper {
  width: 110px;
  float: right;
  height: 40px;
}

.month-select .react-datepicker__input-container {
  height: 40px !important;
}

.location-td {
  padding: 15px !important;
  width: 18%;
}

.project-save {
  background: #fff !important;
  border: 1px solid #847bff !important;
  color: #847bff !important;
}

.project-close {
  border: 1px solid #847bff !important;
  background: #847bff !important;
  color: #fff !important;
}
/* .inventory-inner-container {
    width: calc(100% - 335px);
} */

.single-meta-data-detail {
    padding: 20px;
}

.single-meta-data-detail .overflow-ellipsis {
    font-size: 24px;
    font-weight: 700;
}

.single-meta-data-detail span {
    text-align: center;
    font-size: 12px;
    letter-spacing: 0;
    color: #8E99AF;
}

.single-meta-data-detail span.unit-text {
    color: #888;
}

.item-data-display h3 {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px;
    padding: 0 10px;
}

.item-data-display p {
    line-height: 23px;
    padding: 0 10px;
    margin-bottom: 0;
    font-size: 16px;
    color: #555;
}

.items-details {
    padding: 15px;
    background: #fff;
    margin-top: 23px;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    box-shadow: 0px 6px 12px #0000000D;
    text-align: left;
}

.add-more-inventory {
    background: #fff;
    border: 1px solid #4FB3FF;
    color: #4FB3FF;
    float: left;
    padding: 7px;
    width: 250px;
    border-radius: 5px;
    margin: 0 0 15px 10px;
}

.add-more-inventory:focus {
    outline: 0;
}

.send-req-tabs {
    text-align: left;
    width: 100%;
    margin-top: 30px;
}

.send-req-tabs ul {
    padding: 0 35px;
    margin-bottom: 0;
    list-style: none;
    border-bottom: 1px solid #eee;
}

.send-req-tabs ul li {
    outline: 0;
    font-size: 20px;
    font-weight: bold;
    color: #8E99AF;
    text-transform: uppercase;
    padding: 0 10px 15px;
    display: inline-block;
    cursor: pointer;
    position: relative;
}

.send-req-tabs ul li.react-tabs__tab--selected {
    color: #000;
    font-size: 20px;

}

.send-req-tabs ul li.react-tabs__tab--selected:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -2px;
    left: 0;
    background: #4FB3FF;
}

.send-req-tabs .react-tabs__tab-panel {
    max-height: calc(100vh - 85px);
    overflow-y: auto;
}

.send-btn:hover {
    color: #fff;
}

.active-panel {
    color: #000 !important;
    border-bottom: solid 3px #4FB3FF !important;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: uppercase;
    border: none;
    background: #fff;
    font-family: 'Lato', sans-serif;
    outline: 0;
    padding: 16px 0;
    padding-right: 31px;
}

.button-request,
.button-send {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    color: #8E99AF;
    text-transform: uppercase;
    border: none;
    background: #fff;
    font-family: 'Lato', sans-serif;
}

.send-req-tabs button:focus {
    outline: 0;
}

.edit-button {
    float: right;
    margin-top: 6px;
    margin-left: auto;
}

.label-sub-text {
    font-weight: normal;
    color: #888;
}

.item-location-button {
    width: 36px;
    height: 36px;
    border: none;
    padding: 0;
    border-radius: 5px;
    float: left;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all ease-out 0.2s;
}

.location-edit-button {
    background: #8E99AF url(/static/media/edit-white.6c92bc86.svg) no-repeat;
    background-size: 19px;
    background-position: center;
}

.save-button {
    background: #8E99AF url(/static/media/save-white.7f3987c4.svg) no-repeat;
    background-size: 18px;
    background-position: center;
}

.comment-button {
    background: #4FB3FF url(/static/media/comment-white.d58a17bc.svg) no-repeat;
    background-size: 19px;
    background-position: center
}

.delete-button {
    background: #F2F1FF url(/static/media/delete-black.650cdc57.svg) no-repeat;
    background-size: 19px;
    background-position: center;
}

.comment-button,
.item-locations-table tbody tr:hover .item-location-button {
    visibility: visible;
    opacity: 1;
    z-index: 1
}

.location-list {
    max-width: 300px
}

.comment-btn-item {
    width: 24px;
    height: 24px;
    border: none;
    padding: 0;
    border-radius: 5px;
    float: right;
    margin-left: 8px;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all ease-out 0.2s;
}

.comment-edit-btn {
    background: #aaa url(/static/media/edit-white.6c92bc86.svg) no-repeat;
    background-size: 16px;
    background-position: center;
}

.comment-delete-btn {
    background: #eee url(/static/media/delete-black.650cdc57.svg) no-repeat;
    background-size: 16px;
    background-position: center;
}

.single-item:hover .comment-edit-btn,
.single-item:hover .comment-delete-btn {
    visibility: visible;
    opacity: 1;
    z-index: 1;
}

.single-item {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    text-align: left;
    padding: 15px;
    margin-top: 15px;
}

.single-item p {
    margin-bottom: 0;
    line-height: 22px;
}

#order-type {
    text-transform: capitalize;
}

.single-item .meta-details {
    color: #8E99AF;
    line-height: 25px;
    margin-bottom: 10px;
}

.activity-item .meta-details {
    margin-bottom: 5px;
}

.single-item .meta-details span {
    background: #4fb3ff1c;
    color: #0093FF;
    font-weight: 500;
    padding: 2px 6px;
    border-radius: 4px;
    line-height: 20px;
    margin-right: 5px;
    display: inline-block;
}

.single-item .meta-details .activity-location {
    float: right;
    margin-right: 0;
}

select:focus,
select:active {
    outline: 0 !important;
}

html body .item-locations-table th {
    text-align: left;
    font-size: 12px;
    letter-spacing: 0;
    color: #8E99AF;
    text-transform: uppercase;
}

.w-50 {
    width: 50%;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.max-width-200 {
    max-width: 200px
}

.inventory-location {
    display: block;
    width: 235px;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.span-location {
    background: #f2f1ff;
    margin: 2px 2px;
    padding: 2px 8px;
    border-radius: 4px;
    float: left;
}

.order-loc-from {
    background: #F2F1FF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 8px;
}

.order-loc-to {
    background: #F2F1FF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 8px 15px;
}

.ord-recv {
    text-align: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: #4FB3FF;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #4EB1FF;
    border-radius: 8px;
    padding: 8px 15px;
}

.in-route-order {
    background: #FEF5EA 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 8px 15px;
}

.order-location-qty {
    color: #999;
    font-weight: 300;
    font-size: 13px;
    margin-left: 2px;
}

.blue {
    color: #847BFF;
}

.orange {
    color: #FFA43A;
}

.red {
    color: #F01C24;
}

.orders-head th p {
    width: 140px;
    margin-bottom: 0;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    display: none;
}

.order-item-name {
    float: left;
    font-size: 20px;
    font-weight: 600;
}

.order-item-name-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.selection-div {
    float: right;
}

.selection-div span {
    text-align: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: #8E99AF;
    padding-right: 10px;
}

.selection-div select {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    padding: 6px 8px;
}

.comment-item-name {
    float: left;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 18px;
}

.items-span {
    background: #E8EBF0;
    color: #4FB3FF;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 4px 5px;
    border-radius: 3px;
    font-weight: 500;
    margin-left: 3px;
}

.activity-date {
    font-size: 12px;
    font-weight: 600;
}

.small-blue-text {
    color: #4FB3FF;
    font-size: 0.75rem
}

#item-description {
    height: 80px;
}

.sub-heading {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #8E99AF;
    margin-bottom: 15px;
}

.item-btn {
    border: 0;
    font-size: 24px;
    line-height: 38px;
    width: 42px;
    height: 38px;
    float: left;
    border-radius: 5px;
    margin-right: 10px;
    background: #F2F1FF;
    transition: all ease-out 0.2s;
}

.add-item-btn:hover {
    color: #fff;
    background: #4FB3FF;
}

.delete-item-btn {
    background: #F2F1FF url(/static/media/delete-black.650cdc57.svg) no-repeat;
    background-size: 19px;
    background-position: center;
}

.delete-item-btn:hover {
    background: #ccc url(/static/media/delete-black.650cdc57.svg) no-repeat;
    background-size: 19px;
    background-position: center;
}

.error-label {
    margin: -15px -15px 0;
    text-align: left;
    padding: 5px 30px;
    background: #C8E8FF;
    border-radius: 4px 4px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

.error-label span {
    display: inline-block;
    background: #ECF7FF;
    border-radius: 4px;
    color: #0093FF;
    padding: 3px 7px;
    font-size: 13px;
    font-weight: 500;
    margin-left: 5px;
}

.width-200 {
    width: 200px;
}

.width-100 {
    width: 100px;
}

.width-60 {
    width: 60px;
}

.width-30 {
    width: 30px;
}

.goto-btn {
    background: #4fb3ff url(/static/media/arrow-right-white.ae1834c0.svg) no-repeat;
    background-size: 14px;
    background-position: center;
}

.row-padd {
    padding-bottom: 10px;
}

.org-inventory {
    position: relative;
    background: #fff;
    padding: 15px;
    margin-bottom: 30px;
    border-bottom: 4px solid #4fb3ff !important;
    border-radius: 5px;
    box-shadow: 0px 0px 3px #bbb;
    transition: 0.15s ease-in box-shadow;
}

.org-inventory h5 {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 600;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

html body .custom-btn {
    /* border: 1px solid #4FB3FF !important; */
    color: #4FB3FF !important
}

html body .custom-btn:hover,
html body .custom-btn:focus {
    background: #4FB3FF;
    color: #fff !important;
}
.user-h1 {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    line-height: 35px;
    text-align: left;
}

.user-table {
    margin-top: 15px;
    text-align: left;
}

.user-table thead {
    font-weight: 600;
    border-top: 0;
}

.user-table thead tr>td {
    border-top: 0;
}

.add-u-head {
    border-bottom: 1px solid #E4E4E4;
    padding: 10px 27px;
}

.active-indication,
.inactive-indication {
    width: 16px;
    height: 16px;
    border-radius: 20px;
    background: #ff98d6;
    display: inline-block;
}

.inactive-indication {
    background: #F03E46;
}

.width-125 {
    width: 125px;
}

.delete-btn {
    width: auto;
    height: 30px;
    padding: 0 20px;
    display: inline-block;
    background: #fff;
    border-color: transparent;
    outline: 0 !important;
}

.delete-btn.active {
    background: url(/static/media/delete-black.650cdc57.svg) no-repeat;
    background-size: 16px;
    background-position: 50% 40%;
    cursor: pointer;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: all ease-out 0.2s;
}

.delete-btn.disabled {
    cursor: auto;
    cursor: initial;
}

.user-table tr:hover .delete-btn {
    opacity: 1;
    z-index: 1;
    visibility: visible;
}
.entry-save.user-save {
    background: #FF98D6 !important;
    border: 1px solid #FF98D6 !important;
}
.entry-close.user-close {
    border: 1px solid #FF98D6 !important;
    color: #ff98d6 !important;
}

.project-image {
  height: 150px !important;
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  background: #F2F1FF url(/static/media/project-sample.c3d53e5d.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}

.main-project-div {
  box-shadow: 0px 0px 3px #bbb;
  border-radius: 5px;
  margin: 15px 0;
  min-height: 260px;
  border-bottom: 4px solid #847BFF !important;
  transition: 0.15s ease-in box-shadow;
}

.main-project-div:hover {
  box-shadow: 0px 2px 10px #bbb;
}

.main-project-div.add-project {
  border-bottom: 0 !important;
  box-shadow: 0px 0px 1px #bbb;
  background: #EBECFB;
  position: relative;
}

.add-project-button {
  cursor: pointer;
  border: 0;
  outline: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(/static/media/plus-violet.0898e1c0.svg) no-repeat;
  background-size: 50px;
  background-position: center;
}

.project-single-meta-data {
  background: #fff;
  padding: 10px;
  position: relative;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #000000;
  padding-top: 5px;
  line-height: 30px;
}

.main-project-div .overflow-ellipsis {
  text-align: left;
  font-weight: 600;
  padding: 0;
  max-width: calc(100% - 20px);
}

.custom-table .overflow-ellipsis {
  max-width: 150px;
}

.project-single-meta-data .goto-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.pro-date {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  color: #8E99AF;
  font-size: 12px;
}

.pro-locs {
  text-align: left;
  font: Bold 12px/15px Lato;
  letter-spacing: 0;
  color: #8E99AF;
}

.charts-desc {
  padding-bottom: 15px;
}

.project-progress-charts img {
  max-width: 100%;
  border-radius: 5px;
}

.check-list-head {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #000000;
  margin: 0 0 15px;
}

.checklist-table {
  text-align: left;
}

.bold-head {
  font-weight: 600;
}

.checklist-table td {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  color: #000000;
  padding: 6px 30px;
  border-bottom: 1px solid #E4E4E4;
  border-top: 0;
}

.checklist-table thead {
  border-radius: 10px 10px 0 0;
}

.custom-radio-btn {
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  width: 16px;
  height: 16px;
}

.custom-radio-btn input {
  position: absolute;
  left: -9999px;
}

.custom-radio-btn input:checked+span:before {
  box-shadow: inset 0 0 0 0.32em #847bff;
}

.custom-radio-btn span {
  display: flex;
  align-items: center;
  border-radius: 99em;
  transition: 0.25s ease;
}

.custom-radio-btn span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #847bff;
}

.consumable-div {
  height: 100px;
  background: #ECF6FF;
}

.consumable-div .custom-btn {
  width: auto;
  height: 35px;
  padding: 0 20px;
  border-radius: 5px;
  outline: 0 !important;
  font-weight: 600;
  box-shadow: 0px 6px 6px #0000000D;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 34px;
  transition: background 0.2s linear;
  margin: 10px;
  float: right;
}

.consumable-div .custom-btn.goto-btn {
  background: #fff url(/static/media/arrow-right.4a72a12a.svg) no-repeat;
  background-size: 16px;
  background-position: center;
  border: 0;
  box-shadow: 0 0 0;
  transition: background 0.3s ease-out;
  border-radius: 5px 0px 4px 0px;
}

.projects-button:hover {
  background-color: #847BFF !important;
  background-size: 16px;
  background-position: center;
  border: 0;
  box-shadow: 0 0 0;
  transition: background 0.3s ease-out;
  border-radius: 5px 0px 4px 0px;
}


.member-img {
  width: 48px;
  height: 48px;
  background: #e6e4ff 0% 0% no-repeat padding-box;
  border-radius: 48px;
  margin-top: 10px;
  line-height: 48px;
  text-align: center;
  color: #847bff;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 1px;
}

.member-img.labour-member-img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-top: 0;
  line-height: 40px;
  font-size: 14px;
}

.comm-border {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
}

.org-mem-details {
  padding: 10px 0;
  margin-bottom: 5px;
}

.org-mem-details p {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: #8E99AF;
  margin-bottom: 1px;
}

.org-mem-details h5 {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  color: #000000;
}

.org-mem-row {
  margin: 0 !important;

}

.org-mem-page-link {
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 30px #D1D5DF80;
  border: 1px solid #EDF0F7;
  border-radius: 0px 0px 20px 20px;
}

.org-mem-page-link ul {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
}

.org-mem-page-link li {
  width: 33.333%;
  float: left;
  margin: 20px 0 0 0;
}

.org-mem-page-link .prev {
  float: left;
  padding-left: 65px;
  font-size: 16px;
  letter-spacing: 0;
  color: #BDC4D5;
  position: relative;
}

.org-mem-page-link .next {
  float: right;
  padding-right: 65px;
  font-size: 16px;
  letter-spacing: 0;
  color: #BDC4D5;
  position: relative;
}

.active-slide {
  font-size: 16px;
  letter-spacing: 0;
  color: #858997;
}

.org-mem-page-link .next:hover,
.org-mem-page-link .prev:hover {
  color: #4FB3FF;
  text-decoration: none;
}

.prev::before {
  width: 25px;
  height: 25px;
  content: " ";
  background: url(/static/media/arrow.f9e2888c.svg ) no-repeat;
  background-size: 25px 26px;
  position: absolute;
  left: 34px;
}

.next::before {
  width: 22px;
  height: 22px;
  content: " ";
  background: url(/static/media/right-arrow.1b53d6d9.svg) no-repeat;
  background-size: 22px 22px;
  position: absolute;
  right: 34px;
}

.org-mem-page-link .next:hover:before {

  width: 22px;
  height: 22px;
  content: " ";
  background: url(/static/media/right-arrow-blue.cff4d8b9.svg) no-repeat;
  background-size: 22px 22px;
  position: absolute;
  right: 34px;
}

.org-mem-page-link .prev:hover:before {
  width: 25px;
  height: 25px;
  content: " ";
  background: url(/static/media/arrow-blue.5ad4a9d8.svg) no-repeat;
  background-size: 25px 26px;
  position: absolute;
  left: 34px;
}

/* /................pop-up styles.................../ */

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0, 0, 0, 0.3) 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 999;
  overflow-y: auto;
}

.entry-select {
  width: 100%;
}

.entry-save,
.entry-close {
  border: 1px solid #4EB1FF;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: uppercase;
  float: right;
  width: 80px;
  line-height: 30px;
  padding: 0;
  outline: 0;
}

.entry-save {
  background: #4FB3FF 0% 0% no-repeat padding-box;
  border: 1px solid #4EB1FF;
  color: #FFFFFF;
  margin-left: 10px;
  padding: 1px;
}

.entry-close {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #4FB3FF;
}

.row-entry-head {
  margin-top: 3px;
  border-bottom: 1px solid #E4E4E4;
  padding-bottom: 16px;
}

.popup_inner .mob-no-input {
  padding: 9px 5px;
}

.checklist-weeks,
.checklist-month {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding:  8px 20px 8px 18px;;
  float: right;
  margin-bottom: 10px;
  text-align: center;
}

.checklist-design {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 8px 30px 8px 10px;
  float: left;
  margin-bottom: 10px;
  width: 80%;
  margin-top: 10px;
}

.checklist-weeks {
  margin-left: 30px;
}

.labour-year {
  padding: 7px 10px !important;
}

.right-selection {
  padding: 0 !important;
}
.year{
  text-align: left;
  text-align: initial;
}
.checklist-weeks:focus,
.checklist-month:focus,
.year-name:focus {
  outline: 0;
}

.year-name {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 8px 10px;
  width: 38%;
  text-align: center;
  left: -55px;
}

.custom-table tr td,
.custom-table .abour-th th {
  vertical-align: middle;
}

.custom-table tr td img {
  width: 15px;
}

.custom-table tr td .name-img {
  width: 50px;
}

.uppercase {
  text-transform: uppercase;
}

.labour-link {
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  float: left;
}

.labour-link:hover {
  background: #9d95ff 0% 0% no-repeat padding-box;
}

.labour-link img {
  width: 18px;
  height: 18px;
}

.labour-table tbody td p {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  color: #000000;
  margin-bottom: 0;
}

.labour-th p {
  margin-bottom: 0;
  vertical-align: middle;
  font-size: 13px;
  text-align: center !important;
}

.inline-display input {
  width: 110px;
  float: right;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
}

.inline-display input:focus {
  outline: none;
}

.inline-display .checklist-weeks,
.inline-display .checklist-month {
  margin-left: 0;
  margin-right: 10px;
}

.labour-details {
  padding: 5px 31px;
  padding-top: 15px;

}

.labour-details p {
  text-align: left !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #8E99AF !important;
  margin-bottom: 0;
}

.labour-details h5 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #000000;
  padding-bottom: 7px;
}

.go-back {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #4EB1FF;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 11px;
  letter-spacing: 0;
  color: #4FB3FF;
  text-transform: uppercase;
  margin-right: 10px;
}

.total-wage-div {
  background: #fff;
  padding: 5px 12px;
}

.labour-table-detail {
  background: #FFF;
  padding: 15px !important;
  border-radius: 0px 0px 10px 10px;
}

.labour-popup-table {
  padding: 0 10px 10px;
}

.labour-first-row {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #E4E4E4;
  border-radius: 10px 10px 0px 0px;
  padding: 12px 29px;
}

.labour-popup-table table th {
  padding: 15px 20px !important;
}

.labour-popup-table table tr td {
  padding: 5px 20px !important;
}

.total-wage-div p {
  text-align: left !important;
  font-size: 12px;
  letter-spacing: 0;
  font-weight: 500 !important;
  color: #8E99AF !important;
  margin-bottom: 2px;
}

.total-wage-div h4 {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0;
  color: #000000;
  font-weight: 600;
  line-height: 32px;
}

.total-div {
  border-bottom: none !important;
  margin-top: 10px;
}

.total-div p,
.total-div h4 {
  color: #847bff !important;

}

.labour-popup-table table {
  border: 1px solid #9e9e9e36;
  box-shadow: 0 0 0;
  margin-bottom: 0 !important;
}

.pop-consum-register {
  padding: 15px 22px;
}

.popup-title {
  text-align: left;
  font-size: 18px;
  letter-spacing: 1px;
  color: #000000;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  line-height: 32px;
}

.pop-consum-register input,
.pop-consum-register select {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
}

.pop-consum-register label {
  text-align: left;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
  color: #000000;
  font-weight: 600;
}

.pop-consum-register input:focus,
.pop-consum-register select:focus {
  outline: 0;
}

.consumable-blocks {
  position: relative;
  background: #fff;
  padding: 15px;
  margin-bottom: 30px;
  border-bottom: 4px solid #847bff !important;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #bbb;
  transition: 0.15s ease-in box-shadow;
}

.consumable-blocks:hover {
  box-shadow: 0px 2px 10px #bbb;
}

.consumable-blocks p {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
  color: #8E99AF;
  margin-bottom: 2px;
}

.consumable-blocks h5 {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.consumable-link-btn {
  background: url(/static/media/arrow-right.4a72a12a.svg) no-repeat;
  background-size: 18px;
  background-position: center;
  border: 0;
  transition: background 0.3s ease-out;
  border-radius: 5px 0px 4px 0px;
}

.consumable-blocks .consumable-link-btn {
  position: absolute;
  width: auto;
  top: 0;
  right: 0;
  height: 35px;
  padding: 0 20px;
  display: inline-block;
  border-radius: 5px;
  outline: 0 !important;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 34px;
  margin: 6px;
}

.consumable-blocks .consumable-link-btn.consumable-arrow {
  position: absolute;
  width: auto;
  top: 0;
  right: 14px;
  height: 35px;
  padding: 0 20px;
  display: inline-block;
  border-radius: 5px;
  outline: 0 !important;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 34px;
  margin: 6px;
}

.consumable-blocks:hover .consumable-link-btn {
  background: url(/static/media/arrow-right-blue.c3d61d2d.svg) no-repeat;
  background-size: 18px;
  background-position: center;
  border: 0;
  border-radius: 5px 0px 4px 0px;
}

.clickable-block {
  outline: 0 !important;
  text-decoration: none !important;
}

.popup_inner_check {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 10%;
  margin: auto;
  border-radius: 20px;
  background: white;
  padding: 15px 10px;

}

.task-h5 {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  color: #000000;
  text-transform: uppercase;
  font-weight: 600;
  padding-top: 20px;
  margin-bottom: 0;
}

.checklist-head label {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  color: #000000a6;
  padding-top: 17px;
  padding-bottom: 15px;
}

.add-task-btn {
  background: #F2F1FF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  float: left;
  border: 0;
  vertical-align: middle;
  width: 100%;
  padding: 8px;
  margin-top: 14px;
}

.add-task-btn img {
  width: 16px;
  height: 16px;
}

.add-task-btn p {
  margin-bottom: 0;
  color: #10b999;
  padding: 1px 0;
}

.consumable-direct {
  position: relative;
}

.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.react-datepicker-popper {
  z-index: 10 !important
}

.react-datepicker-wrapper {
  display: block !important;
}

.custom-file,
.custom-file-input,
.custom-file-label {
  margin: 0 !important;
  height: 38px !important;
}

.custom-file-label {
  line-height: 1.8 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-file-label::after {
  height: 36.5px !important;
  line-height: 1.8 !important;
}

.custom-file-input:focus~.custom-file-label {
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25) !important
}

.form-group label {
  font-weight: 600;
  text-align: left;
}

.lab-allow-btn {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  opacity: 1;
  padding: 5px;
  line-height: 20px !important;
}

.row-bb {
  border-bottom: 1px solid #E4E4E4;
}

.total-div p {
  margin-bottom: 0;
}

.labour-btn-adds {
  background: #ECF6FF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  opacity: 1;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #4FB3FF;
  text-transform: uppercase;
  margin: 0 auto
}

.adv-checkbox .custom-checkbox {
  background: #4FB3FF !important;
}

.adv-checkbox input[type="checkbox"]:checked+.custom-checkbox {
  border-color: #4FB3FF !important;
}

.track-labour {
  margin-top: 1rem;
}

.m-0 {
  margin: 0;
}

.m-t-10 {
  margin-top: 10px;
}

.labour-date-input {
  width: 150px;
}

.ot-hours-input {
  width: 75px;
  margin: 0 auto;
}

.custom-btn.add-user-btn {
  border: 1px solid #ff98d6 !important;
  color: #ff98d6 !important;
}

.custom-btn.add-user-btn:hover {
  background: #ff98d6 !important;
  color: #fff !important;
}

.entry-save.project-save {
  background: #847bff !important;
  border: 1px solid #847bff !important;
  color: #fff !important;
}

.entry-close.project-close {
  border: 1px solid #847bff !important;
  color: #847bff !important;
  background: #fff !important;
}

.vehicle-input {
  padding-top: 10px;
  padding-bottom: 10px;
}


.more-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 95;
}
.edit-menu{
  margin-top: 7px;
}

.org-inventory ul {
  position: absolute;
  background: #fff;
  right: 0;
  top: 16px;
  width: 75px;
  padding: 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .1), 0 1px 3px 1px rgba(60, 64, 67, .1);
  border-radius: 8px;
  text-align: center;
  border: 1px solid #eee;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: .25s ease-out 0s;
}

.expand-cell-header,
th.sortable,
.expand-cell {
  cursor: pointer;
}

.table thead th:focus {
  outline: 0;
}

.display-hide {
  visibility: hidden;
}

.caret,
.caret-4-asc,
.caret-4-desc {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  margin-top: -2px;
  vertical-align: middle;
  border-top: 5px dashed;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.caret-4-asc {
  content: "";
  border-top: 0;
  border-bottom: 5px dashed;
  border-bottom: 5px solid;
}

.reset-expansion-style {
  background: #f4f5f6;
}

.custom-edit-button {
  display: inline-block;
  width: 36px;
  height: 36px;
  background: #847bff url(/static/media/edit-white.6c92bc86.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  border: 1px solid #847bff;
  border-radius: 5px;
  outline: 0;
  margin-right: 10px;
}

.custom-delete-button {
  display: inline-block;
  width: 36px;
  height: 36px;
  background: #847bff url(/static/media/delete-white.372fb57e.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  border: 1px solid #847bff;
  border-radius: 5px;
  outline: 0;
}

.pagination {
  float: right;
}

.page-link {
  color: #847bff !important;
}

.page-item.active .page-link {
  background-color: #847bff !important;
  border-color: #847bff !important;
  color: #fff !important;
}

.page-link:focus {
  box-shadow: 0 0 0 !important;
}

.total-button {
  background-color: rgba(132, 123, 255, 0.25);
  color: #000000a6;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  float: right;
  padding: 0 15px;
  line-height: 40px;
  margin-right: -15px;
}

.total-style {
  margin: 0;
}

.amout-style {
  margin-top: 0;
  margin-bottom: 0rem;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bolder;
}

.pop-up-custom-width {
  display: table;
}

.text-alig-end {
  text-align: end;
}

.export-btn {
  border: 1px solid#847bff;
  background: #847bff;
  border-radius: 8px;
  color: #fff !important;
  text-align: center;
  float: right;
  line-height: 35px;
  margin-right: 30px;
}

.react-datepicker-popper {
  width: 330px;
}

.react-datepicker__header--time {
  padding: 20px 5px 21px !important;
}

.not-editable-box{
  width: 75px;
  margin: 0 auto;
  text-align: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  opacity: 1;
  padding: 5px;
  line-height: 20px !important;
}
html body .order-module .custom-btn {
    width: auto;
    height: 35px;
    width: auto;
    padding: 0 20px;
    display: inline-block;
    background: #fff;
    border: 1px solid #400080 !important;
    border-radius: 5px;
    color: #400080 !important;
    outline: 0 !important;
    font-weight: 600;
    text-decoration: none !important;
    text-transform: uppercase;
    line-height: 34px;
}

html body .order-module .custom-btn:hover,
html body .order-module .custom-btn:focus {
    background: #400080 !important;
    color: #fff !important;
}

.entry-save.order-save {
    background: #400080 !important;
    border: 1px solid #400080 !important;
    color: #fff !important;
}

.entry-close.order-close {
    background: #fff !important;
    border: 1px solid #400080 !important;
    color: #400080 !important;
}

.page-link {
    color: #400080 !important;
  }
  
  .page-item.active .page-link {
    background-color: #400080 !important;
    border-color:#400080 !important;
    color: #fff !important;
  }

  .order-edit-btn{
    display: inline-block;
    width: 36px;
    height: 36px;
    background: url(/static/media/edit-voilet.0bbe2ee3.svg) no-repeat;
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: center;
    border: 1px solid #847bff00;
    border-radius: 5px;
    outline: 0;
    margin-right: 10px;
  }

  .order-delet-ebtn{
    display: inline-block;
    width: 36px;
    height: 36px;
    background: url(/static/media/delete-voilet.54572b04.svg) no-repeat;
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: center;
    border: 1px solid #847bff00;
    border-radius: 5px;
    outline: 0;
    margin-right: 10px;
  }
.super-admin-tabs {
        list-style: none;
        margin: 0 0 20px;
        padding: 5px 0 0;
        text-align: left;
        float: left;
        margin: 0;
        padding: 0;
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(6, calc(50% - 56px));
        margin-top: 5px;
}

.super-admin-back-button:hover {
        background: #10B999 url(/static/media/arrow-back-white.eee4200d.svg) no-repeat;
        background-size: 16px;
        background-position: center;
        border-color: #10B999;
}

.super-admin-tabs li:hover {
        background: #10B999 0% 0% no-repeat padding-box;
        border: 1px solid #10B999;
        border-radius: 5px;
        color: #fff;
}

.super-admin-tabs li:hover,
.super-admin-tabs li.react-tabs__tab--selected,
.super-admin-tabs li.active-tab {
        background: #10B999 0% 0% no-repeat padding-box;
        border: 1px solid #10B999;
        border-radius: 5px;
        color: #fff;
}

.add-btn-green {
        width: 100%;
        height: 40px;
        padding: 0 8px;
        display: inline-block;
        background: #10B999;
        border: 1px solid #10B999;
        border-radius: 8px;
        color: #fff !important;
        outline: 0 !important;
        font-weight: 500;
        box-shadow: 0px 6px 6px #0000000D;
        text-decoration: none !important;
        text-transform: uppercase;
        line-height: 34px;
        transition: background 0.2s linear;
        margin-top: 10px;
}

.admin-checklist-div,
.units-div {
        padding: 40px;
}

.settings-edit-button {
        background: url(/static/media/edit-green.f39e59b4.svg) no-repeat;
}

.settings-delete-button {
        background: url(/static/media/delete-green.80439160.svg) no-repeat;
}

.settings-toggle-button {
        background: url(/static/media/down-black.782a9aec.svg) no-repeat;
        background-size: 14px !important;
}

.active-accordion .settings-toggle-button {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
}

.settings-btn {
        width: 32px;
        height: 32px;
        margin: 8px 6px;
        background-size: 32px;
        background-position: center;
}

.tab-first {
        text-align: left !important;
        font-size: 16px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 600 !important;
        opacity: 1;
        margin: 12px 0;
        padding: 0 15px !important;

}
.titleContent {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
.card-header {
        background-color: #fff !important;
        padding: 0 !important;
}

.card-body {
        padding: 0rem !important;
}

.accor-body {
        border-bottom: 1px solid #E4E4E4;
        padding-top: 10px;
        display: block;
        height: 100%;
        max-height: 500px;
        overflow-y: scroll;
}

.accor-body p {
        text-align: left;
        text-align: left;
        margin-bottom: 8px;
}

.checklist-item-desc {
        padding: 0 15px;
        text-align: left;
}

.accor-body input {
        margin-top: 5px;
}

.tab-second {
        text-align: left;
        font-size: 16px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 600;
        opacity: 1;
        padding: 10px 0 0 15px;
}

.right-float-edits img {
        padding: 10px;
        cursor: pointer;
}

.right-float-edits .toggle-arrow {
        width: 31px;
        height: 28px;
}

.user-role-div {
        padding: 40px;
}

.entry-close-super {
        border: 1px solid #10B999;
        border-radius: 5px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        text-transform: uppercase;
        float: right;
        width: 80px;
        line-height: 30px;
        padding: 0;
        outline: 0;
}

.green-save {
        background: #10B999 0% 0% no-repeat padding-box;
        border: 1px solid #10B999;
        color: #FFFFFF;
        margin-left: 10px;
}

.green-close {
        color: #10b999;
        border: 1px solid #10b999;
}

.checklist-item-entry {
        position: relative;
}

.checklist-item-entry .delete-btn {
        position: absolute;
        right: 4px;
        top: 4px;
        width: 30px;
        height: 30px;
        border: none;
        padding: 0;
        border-radius: 0.25rem;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        transition: all ease-out 0.2s;
        background: #F2F1FF url(/static/media/delete-black.650cdc57.svg) no-repeat;
        background-size: 16px;
        background-position: center
}

.checklist-item-entry:hover .delete-btn {
        visibility: visible;
        opacity: 1;
        z-index: 1
}

.no-data {
        padding: 0 15px;
        color: #666;
}

.designation-details {
        padding: 5px 20px;
}

.designation-details span {
        display: inline-block;
        min-width: 80px;
}

.adv-checkbox .custom-checkbox {
        background: #10B999 !important;
}

.adv-checkbox input[type="checkbox"]:checked+.custom-checkbox {
        border-color: #10B999 !important;
}
