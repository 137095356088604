.sidebar {
    width: 250px;
    /* min-height: 100vh; */
    height: 100%;
    background: #fff;
    box-shadow: 0px 3px 12px #0000001A;
    float: left;
    position: fixed;
    z-index: 1;
}

.logo-sidebar {
    margin: 20px auto 50px;
}

.sidebar ul {
    padding: 0;
    margin: 0 25px;
    list-style: none;
}

.sidebar ul li {
    margin-bottom: 5px;
}

.sidebar ul li:last-child {
    margin-bottom: 0;
}

.sidebar ul li a {
    font-weight: 700;
    padding: 15px 15px 15px 54px;
    display: block;
    cursor: pointer;
    border-radius: 6px;
    text-decoration: none !important;
    color: #222 !important;
    position: relative;
    text-align: left;
    transition: background 0.3s ease-out;
}

.logout-menu {
    font-weight: 700;
    padding: 15px 105px 10px 54px;
    display: block;
    cursor: pointer;
    border-radius: 6px;
    text-decoration: none !important;
    color: #222 !important;
    position: relative;
    text-align: left;
    transition: background 0.3s ease-out;
    width: fit-content;

}

.logout-menu {
    position: relative;
    bottom: -154px !important;
    left: 25px;
    right: 25px;
}

/* .sidebar ul li:hover a,
.sidebar ul .active-menu-item a {
    color: #4FB3FF;
    background: #ECF6FF;
}  */
.dashboard-menu:hover a,
.sidebar ul .dashboard-menu.active-menu-item a {
    color: #FFA43A !important;
    background: #FEF5EA !important;
}

.plant-machinery-menu:hover a,
.sidebar ul .plant-machinery-menu.active-menu-item a {
    color: #4FB3FF !important;
    background: #4fb3ff1c !important;
}

.projects-menu:hover a,
.sidebar ul .projects-menu.active-menu-item a {
    color: #847BFF !important;
    background: #847bff33;
}

.orders-menu:hover a,
.sidebar ul .orders-menu.active-menu-item a {
    color: #400080 !important;
    background: rgba(64, 0, 128, 0.1) !important;
}

.settings-menu:hover a,
.sidebar ul .settings-menu.active-menu-item a {
    color: #10b999 !important;
    background: rgba(16, 185, 153, 0.12) !important;
}

.users-menu:hover a,
.sidebar ul .users-menu.active-menu-item a {
    color: #FF98D6 !important;
    background: #FFF3FA;
}

.sidebar ul li a span,
.settings-menu span,
.logout-menu span {
    width: 30px;
    height: 31px;
    position: absolute;
    left: 11px;
    top: 10px;
    border-radius: 6px;
}

.sidebar ul li a span.active-icon,
.sidebar ul li:hover a span.inactive-icon,
.sidebar ul .active-menu-item a span.inactive-icon {
    display: none;
}

.sidebar ul .active-menu-item a span.active-icon,
.sidebar ul li:hover a span.active-icon {
    display: inline-block;
}

.dashboard-menu .active-icon {
    background: #fff url("../../../assets/img/icons/dashboard-active.svg") no-repeat;
    background-position: center;
    background-size: 15px;
}

.plant-machinery-menu .active-icon {
    background: #fff url("../../../assets/img/icons/plant-active.svg") no-repeat;
    background-position: center;
    background-size: 18px;
}

.consumable-register-menu .active-icon {
    background: #fff url("../../../assets/img/icons/consumable-active.svg") no-repeat;
    background-position: center;
    background-size: 18px;
}

.projects-menu .active-icon {
    background: #fff url("../../../assets/img/icons/project-active.svg") no-repeat;
    background-position: center;
    background-size: 18px;
}

.orders-menu .active-icon {
    background: #fff url("../../../assets/img/icons/orders-active.svg") no-repeat;
    background-position: center;
    background-size: 22px;
}

.settings-menu .active-icon {
    background: #fff url("../../../assets/img/icons/settings-active.svg") no-repeat;
    background-position: center;
    background-size: 18px;
}

.logout-menu .active-icon {
    background: #fff url("../../../assets/img/icons/logout-active.svg") no-repeat;
    background-position: center;
    background-size: 18px;
}

.dashboard-menu .inactive-icon {
    background: #F7F8FA url("../../../assets/img/icons/dashboard-icons-black.svg") no-repeat;
    background-position: center;
    background-size: 15px;
}

.plant-machinery-menu .inactive-icon {
    background: #F7F8FA url("../../../assets/img/icons/plant.svg") no-repeat;
    background-position: center;
    background-size: 18px;
}

.projects-menu .inactive-icon {
    background: #F7F8FA url("../../../assets/img/icons/project.svg") no-repeat;
    background-position: center;
    background-size: 18px;
}

.consumable-register-menu .inactive-icon {
    background: #F7F8FA url("../../../assets/img/icons/consumable.svg") no-repeat;
    background-position: center;
    background-size: 18px;
}

.orders-menu .inactive-icon {
    background: #F7F8FA url("../../../assets/img/icons/orders.svg") no-repeat;
    background-position: center;
    background-size: 22px;
}

.settings-menu .inactive-icon {
    background: #F7F8FA url("../../../assets/img/icons/settings.svg") no-repeat;
    background-position: center;
    background-size: 18px;
}

.logout-menu .inactive-icon {
    background: #F7F8FA url("../../../assets/img/icons/logout.svg") no-repeat;
    background-position: center;
    background-size: 16px;
}

.users-menu .active-icon {
    background: #fff url("../../../assets/img/icons/users-blue.svg") no-repeat;
    background-position: center;
    background-size: 18px;
}

.users-menu .inactive-icon {
    background: #F7F8FA url("../../../assets/img/icons/users.svg") no-repeat;
    background-position: center;
    background-size: 15px;
}

.form-control.search {
    box-shadow: 0px 6px 12px #0000000D;
    border: 1px solid #f5f6f7;
    border-radius: 8px;
    font-size: 0.95rem;
    height: auto;
    line-height: 30px;
    padding-right: 50px;
}

.form-control.search:focus {
    border-color: #f1f2f3;
    outline: 0;
    box-shadow: 0px 6px 20px #0000000D
}

.topbar form {
    position: relative;
}

.topbar form button {
    border: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../../../assets/img/icons/search.svg") no-repeat;
    background-size: cover;
    background-position: center;
    outline: 0;
    position: absolute;
    right: 18px;
    top: 12px;
}

.search-results {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 50px;
    background: #fff;
    text-align: left;    
    box-shadow: 0px 6px 12px #0000000D;
    border: 1px solid #f5f6f7;
    border-radius: 8px;
    z-index: 999;
    padding: 15px 20px;
    max-height: 400px;
    overflow-y: auto;
}

.search-results h4 {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #8E99AF;
}

.search-results ul {
    padding: 0;
    list-style: none;
    margin: 0 0 10px;
}

.search-results ul li a {
    display: inline-block;
    line-height: 30px;
    color: #212529 !important;
    text-decoration: none;
}

.search-results ul li a:hover {
    color: #4fb3ff !important;
}

.notification-icon,
.user-avatar,
.mobile-menu-button {
    width: 45px;
    height: 45px;
    border: 1px solid #eee;
    border-radius: 50%;
    outline: 0 !important;
    transition: background 0.1s linear;
}

.notification-icon {
    background: #fff url("../../../assets/img/icons/notification.svg") no-repeat;
    background-size: 20px;
    background-position: center;
}

.notification-icon:hover {
    background: #4FB3FF url("../../../assets/img/icons/notification-white.svg") no-repeat;
    background-size: 20px;
    background-position: center;
}

.user-avatar {
    background: #fff url("../../../assets/img/icons/user.svg") no-repeat;
    background-size: 20px;
    background-position: center;
}

.user-avatar:hover {
    background: #4FB3FF url("../../../assets/img/icons/user-white.svg") no-repeat;
    background-size: 20px;
    background-position: center;
}

.mobile-menu-button {
    background: #fff url("../../../assets/img/icons/menu-button.svg") no-repeat;
    background-size: 20px;
    background-position: center;
    display: none;
}

.mobile-menu-button:hover {
    background: #4FB3FF url("../../../assets/img/icons/menu-button-white.svg") no-repeat;
    background-size: 20px;
    background-position: center;
}

.project-progress-charts {
    box-shadow: 0px 6px 12px #0000000D;
    border-radius: 5px;
}

.user-details-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: -6px 0px 15px #3B86FF1A;
    position: fixed;
    right: -350px;
    top: 0;
    bottom: 0;
    width: 350px;
    text-align: left;
    padding: 15px;
    z-index: 999;
    transition: 0.15s right ease-out;
}

.user-details-content.profile-sidebar-active {
    right: 0;
}

.user-details-content h5 {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0;
    color: #000000;
    font-weight: 600;
    margin-bottom: 10px;
}

.user-details-content h1 {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0;
    color: #4FB3FF;
    text-transform: uppercase;
}

.user-details-content p {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0;
    color: #7F7F7F;
    margin-bottom: 2px;
}

.user-detail h5 {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0;
    color: #000000;
    font-weight: 600;
    margin-bottom: 5px;
}

.user-detail p {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0;
    color: #7F7F7F;
    margin-bottom: 2px;
}

.user-details-content .profile-close-button {
    border: 2px solid #efefef;
    background: transparent;
    padding: 12px 16px;
    border-radius: 54px;
    float: right;
    margin-right: 15px;
    outline: 0;
}

.user-details-content button img {
    width: 12px;
    margin-bottom: 3px;
}

button:active {
    outline: 0 !important;
    box-shadow: none;
}

button:focus {
    outline: 0 !important;
}

.clear-class {
    clear: both;
}

.user-image {
    width: 65px;
    height: 65px;
    background: #ECF6FF 0% 0% no-repeat padding-box;
    border-radius: 65px;
    line-height: 65px;
    text-align: center;
    color: #4FB3FF;
    font-weight: 600;
    font-size: 26px;
    letter-spacing: 1px;
    margin: auto;
}

.user-image-edit {
    background: url("../../../assets/img/user-profile-img.png") no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
    border-radius: 5px;
}

.margin-zero {
    margin: 0 !important;
}

.padding-zero {
    padding: 0 !important;
}

.user-image .edit-user-pro {
    padding: 4px;
    position: absolute;
    right: 0;
    bottom: 11px;
    border-radius: 34px;
    width: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 6px #0000001F;
}

.user-image .edit-user-pro,
.user-image-edit .edit-user-pro {
    padding: 4px;
    position: absolute;
    right: 11px;
    bottom: 11px;
    border-radius: 34px;
    width: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 6px #0000001F;
}

.edit-user-pro img {
    width: 14px;
    margin-left: 3px;
    margin-bottom: 3px;
}

.basic-info-desk {
    padding: 32px 15px;
    border-bottom: 1px solid #00000021;
    width: 99%;
}

.bi {
    margin-bottom: 11px;
}

.cont-info-desk {
    padding: 32px 15px;
}

.log-out-class {
    border: 1px solid #4FB3FF !important;
    color: #4FB3FF;
    font-weight: 600;
    padding: 8px 20px !important;
    border-radius: 7px !important;
    background: #fff;
    margin-right: 10px;
}

.edit-pro-class {
    background: #4FB3FF 0% 0% no-repeat padding-box !important;
    border-radius: 10px;
    color: #fff;
    border: 1px solid #4FB3FF !important;
    color: #fff;
    font-weight: 600;
    padding: 8px 20px !important;
    border-radius: 7px !important;
    float: right;
}

.edits {
    padding-top: 20px;
    padding-left: 15px;
}

.popup_inner_user_edit {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 3%;
    margin: auto;
    border-radius: 20px;
    background: white;
}

.popup_inner_user_edit .entry-close {
    border: 1px solid #4FB3FF;
    border-radius: 5px;
    padding: 6px 16px;
}

.popup_inner_user_edit .entry-save {
    color: #fff !important;
    background: #4FB3FF 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    padding: 6px 19px !important;
    border: 1px solid #4FB3FF !important;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0;
}

.popup_inner_user_edit h5 {
    text-align: left;
    padding-top: 25px;
    letter-spacing: 0;
    color: #000000;
    opacity: 0.5;
}

.edit-f-row {
    padding: 20px 0px 39px 0;
    border-bottom: 2px solid #efefef;
}

.edit-last-row {
    padding: 15px 0px;
}

.edit-last-row input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    padding: 6px;
    margin-bottom: 15px;
}

.edit-f-row input:focus {
    outline: none;
}

.edit-f-row label {
    text-align: left;
    font: Bold 12px/15px Lato;
    letter-spacing: 0;
    color: #8E99AF !important;
    text-transform: capitalize;
    margin-top: 10px !important;
    margin-bottom: 3px !important;
}

.user-details-content-modal {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: -6px 0px 15px #3B86FF1A;
    position: absolute;
    top: 87%;
    right: 26%;
    width: 559px;
    box-shadow: 0px 10px 20px #0024471A;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    text-align: left;
    padding: 15px 15px 0 15px;
    z-index: 99;
    display: none;
}

.user-details-content-modal.notify-active {
    display: block;
}

.user-details-content-modal.notify-active .notification-icon {
    background: #4FB3FF url("../../../assets/img/icons/notification-white.svg") no-repeat;
    background-size: 20px;
    background-position: center;
}

.notify-clear h5 {
    float: right;
    text-align: right;
    font-size: 14px;
    letter-spacing: 0;
    color: #4FB3FF;
    text-transform: uppercase;
    font-weight: 600 !important;
    padding-bottom: 6px;
}

.notify-head h3 {
    float: left;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0;
    color: #000000;
    text-transform: uppercase;
    font-weight: 600;
}

.notification-table .row {
    margin-top: 0;
}

.table-noty td {
    vertical-align: middle;
}

.table-noty {
    margin-bottom: 0 !important;
}

.custom-table.table-noty tr td img {
    width: 28px;
}

.orange {
    color: #FFA43A;
}

.green {
    color: #10B999;
}

.blue {
    color: #10B999;
}

.grey {
    color: #8E99AF;
}

.mobile-logo {
    width: 100px;
}

.mobile-menu {
    width: 40px;
    float: left;
}

.mobile-menu-atcl {
    border-bottom: 1px solid #E4E4E4;
    background: #fff;
    padding: 20px;
}

.noti-button {
    float: right;

}

.noti-button .notification-icon {
    margin-right: 0 !important;
}

.edit-pro-head {
    text-align: left;
    font-size: 18px;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 500;
}

.User-edit .row-entry-head {
    margin-top: 20px;
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 16px;

}