@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

button {
  cursor: pointer;
}

.login-bg form {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

label,
input {
  display: block;
  width: 100%;
}

label {
  margin-bottom: 5px;
  height: 22px;
}

ul li {
  list-style: none;
}

.gray-bg {
  background: #F7F8FA;
}

.login-bg {
  background: url("../img/login-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}

.full-screen {
  width: 100%;
  min-height: 100vh;
}

.vertical-center-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100vh;
}

.overlay {
  background: rgba(0, 0, 0, 0.2);
}

.vertical-align-item {
  display: flex;
  flex-direction: column;
}

.custom-checkbox {
  display: block;
  margin-bottom: 15px;
}

.custom-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom-checkbox label {
  position: relative;
  cursor: pointer;
}

.custom-checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #ddd;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.custom-checkbox input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  left: 6px;
  width: 6px;
  height: 14px;
  border: solid #4FB3FF;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.Toastify__toast {
  padding: 12px !important;
  border-radius: 3px !important;
  margin-bottom: 0;
}

.Toastify__toast-body {
  font-family: 'Lato', sans-serif !important;
  color: #fff !important;
  text-align: left !important;
}

.Toastify__toast--success {
  background: rgb(29 181 93);
}

.inner-container {
  width: calc(100% - 250px);
  min-height: 100vh;
  float: right;
  padding: 20px 40px 15px 40px;
  background: #F7F8FA;
}

.clear {
  clear: both;
}

.coming-soon {
  margin-top: 100px;
  font-size: 1.2rem;
}

.width-100 {
  width: 100%;
}

.material-card {
  background: #fff;
  box-shadow: 0px 6px 12px #0000000D;
  border-radius: 10px;
}

.back-button {
  width: 40px;
  height: 38px;
  display: block;
  background: #fff url("../img/icons/arrow-back.svg") no-repeat;
  background-size: 16px;
  background-position: center;
  text-indent: -999999999999px;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  cursor: pointer;
  float: left;
  margin-top: 4px;
}

.back-button:hover {
  background: #4FB3FF url("../img/icons/arrow-back-white.svg") no-repeat;
  background-size: 16px;
  background-position: center;
  border-color: #4FB3FF;
}

.back-button.projects-back:hover {
  background: #847bff url("../img/icons/arrow-back-white.svg") no-repeat;
  background-size: 16px;
  background-position: center;
  border-color: #847bff;
}

.page-header h1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  line-height: 35px;
}

.custom-btn {
  width: auto;
  height: 35px;
  width: auto;
  padding: 0 20px;
  display: inline-block;
  background: #fff;
  border: 1px solid #4EB1FF;
  border-radius: 5px;
  color: #4FB3FF !important;
  outline: 0 !important;
  font-weight: 600;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 34px;
}

.custom-btn:hover {
  background: #4FB3FF;
  color: #fff !important;
}

.custom-btn.goto-btn {
  background: #F7F8FA url('../img/icons/arrow-right.svg') no-repeat;
  background-size: 14px;
  background-position: center;
  border: 0;
  box-shadow: 0 0 0;
}

.custom-btn.goto-btn:hover {
  background: #4FB3FF url('../img/icons/arrow-right-white.svg') no-repeat;
  background-size: 14px;
  background-position: center;
}

.custom-btn.small-btn:hover {
  background: #ff98d6 url('../img/icons/arrow-right-white.svg') no-repeat;
  background-size: 14px;
  background-position: center;
}

.custom-btn.goto-btn.small-btn {
  height: 30px;
  margin-top: 3px;
}

.no-items-text {
  margin-top: 100px;
}

.form-group label {
  font-weight: 600;
}

.form-control.disabled {
  background: #F2F1FF;
  border-color: #F2F1FF;
}

.activity-item-heading {
  text-align: left;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
}

.custom-table {
  background: #fff !important;
  box-shadow: 0px 6px 12px #0000000D;
  border: 1px solid #f5f6f7;
  border-radius: 10px;
}

.custom-table th {
  border-top: 0 !important;
}

.custom-table tr td {
  line-height: 25px;
  position: relative;
}

.order-type {
  text-transform: capitalize;
}

.custom-tabs {
  list-style: none;
  margin: 0 0 20px;
  padding: 5px 0 0;
  text-align: left;
  float: left;
}

.custom-tabs li {
  background: #fff;
  padding: 0 40px;
  line-height: 35px;
  margin: 0 20px 10px 0;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  outline: 0;
  min-width: fit-content;
}

.custom-tabs.has-link li {
  padding: 0;
}

.custom-tabs li:hover,
.custom-tabs li.react-tabs__tab--selected,
.custom-tabs li.active-tab {
  background: #4FB3FF 0% 0% no-repeat padding-box;
  border: 1px solid #4FB3FF;
  border-radius: 5px;
  color: #fff;
}


.project-tabs.custom-tabs li:hover,
.project-tabs.custom-tabs li.react-tabs__tab--selected,
.project-tabs.custom-tabs li.active-tab {
  background: #847bff 0% 0% no-repeat padding-box;
  border: 1px solid #847bff;
  border-radius: 5px;
  color: #fff;
}

.custom-tabs li a {
  color: #8E99AF !important;
  text-decoration: none;
  display: block;
  padding: 0 20px;
  text-align: center;
}

.custom-tabs li:hover a,
.custom-tabs li.active-tab a {
  color: #fff !important;
}

.single-meta-data {
  background: #fff;
  box-shadow: 0px 6px 12px #0000000D;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 30px;
}

.single-meta-data h2 {
  font-size: 1.4rem;
  font-weight: 600;
}

.single-meta-data span {
  color: #999;
}

.project-single-meta-data span {
  color: #999;
}

.item-data-display h3 {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 25px;
  padding: 0 10px;
}

.item-data-display p {
  line-height: 25px;
  font-size: 1rem;
  padding: 0 10px;
  margin-bottom: 0;
  font-size: 16px;
}

.form-sent {
  padding: 15px 35px 0px 35px;
  text-align: left;
  font-size: 26px;
  font-weight: bold;
  padding-top: 60px;
}

.form-sent-sidebar {
  width: 325px;
  position: fixed;
  right: 10px;
  top: 0;
  min-height: 100vh;
  background: #fff;
  box-shadow: 0px 3px 12px #0000001A;
  z-index: 1;
}

.form-body {
  text-align: left;
  padding: 20px 35px;
}

.full-width {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.send-btn {
  padding: 2px 20px;
  display: inline-block;
  outline: 0 !important;
  font-weight: 500;
  box-shadow: 0px 6px 6px #0000000D;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 34px;
  transition: background 0.2s linear;
  margin-bottom: 5px;
  background: #4FB3FF 0% 0% no-repeat padding-box;
  border: 1px solid #4FB3FF;
  border-radius: 5px;
  color: #fff;
  width: 100%;
}

.add-btn {
  width: 100%;
  height: 40px;
  padding: 0 8px;
  display: inline-block;
  background: #847bff;
  border: 1px solid #847bff;
  border-radius: 8px;
  color: #fff !important;
  outline: 0 !important;
  font-weight: 500;
  box-shadow: 0px 6px 6px #0000000D;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 34px;
  transition: background 0.2s linear;
  margin-top: 10px;
}

.form-control:focus {
  box-shadow: none !important;
}

input:active {
  box-shadow: none !important;
}

.fr-loc,
.to-loc {
  background: #F2F1FF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 0;
  text-align: center;
}

.status-cell {
  background: #FEF5EA;
  border-radius: 15px;
  padding: 3px 5px;
  margin-bottom: 0;
  text-align: center;
}

.green-cell {
  background: #E8F7F3
}

.status-button {
  background: #FFF;
  border: 1px solid #4EB1FF;
  border-radius: 8px;
  color: #4EB1FF;
  padding: 1px 25px;
}

.table-activity thead th,
.table-activity tbody td {
  text-align: left;
  vertical-align: middle;
}

button:focus {
  box-shadow: none !important;
  outline: none;
}

.send-btn-a {
  text-align: center;
}

.back1 {
  margin-right: 12px;
  margin-left: 14px;
}

.add {
  margin-right: 10px;
}

.project_desc {
  text-align: left;
}

.graph {
  height: 250px;
}

.padd-common {
  padding: 50px;
}

.float-left {
  float: left;
  padding-top: 30px;
}

.table th,
.table td {
  border-top: 0;
}

.table tbody tr:last-child td {
  border-bottom: 0;
}

.table thead th {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  vertical-align: middle !important;
  padding-top: 20px;
  line-height: 16px;
  color: #8E99AF;
}

.popup_inner {
  width: 100%;
  max-width: 750px;
  margin: 15px;
  box-shadow: 0 0 50px #777;
  border-radius: 10px;
  background: #fff;
}

.track-labour .popup_inner {
  position: absolute;
  top: 0;
}

.popup_inner_large {
  max-width: 900px;
}

.popup_inner_medium {
  max-width: 600px;
}

.popup_inner_small {
  max-width: 475px;
}

.popup-heads {
  border-bottom: 1px solid #E4E4E4;
  padding: 15px 30px;
}

.material-card-detail {
  background: #fff;
  padding: 20px;
  border-top: 1px solid #E4E4E4;
  border-right: 1px solid #E4E4E4;
  border-left: 1px solid #E4E4E4;
  margin-top: 0;
  border-radius: 5px 5px 0 0;
}

.popup-sub-heading {
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.zero-padding {
  padding: 0 !important;
  border: 1px solid #E4E4E4;
}

.inventory-meta-details {
  border-radius: 5px;
  background: #fff;
  padding: 0 !important;
  box-shadow: 0px 6px 12px #0000000D;
}

.zero-margin {
  margin: 0 !important;
}

.left-bor-radius {
  border-radius: 0 0 0 5px;
}

.popup-body {
  padding: 25px 30px
}

.overflow-popup-body {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.right-bor-radius {
  border-radius: 0 0 5px 0;
}

.item-location-button:focus,
.entry-close:focus,
.labour-link:focus,
.go-back:focus,
.entry-save:focus {
  outline: 0;
}

.entry-close {
  padding: 1px 0;
}

.charts-div img {
  width: 350px;
  height: 250px;
  float: left;
}

.row-class-charts {
  margin-top: 20px;
  padding: 0 15px !important;
}

.row-class-charts img {
  width: 100% !important;
}

.line-chart {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #0000000D;
  border-radius: 8px;
  padding: 20px 15px;
}

.doughnout-chart {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #0000000D;
  border-radius: 8px;
  padding: 20px 15px;

}

.pie-chart {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #0000000D;
  border-radius: 8px;
  padding: 20px 15px;

}

.line-chart h5,
.doughnout-chart h5 {
  text-align: left;
  font: Bold 12px/15px Lato;
  letter-spacing: 0;
  color: #000000;
  text-transform: uppercase;
}

.pie-chart h4 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #10B999;
}

.pie-chart p {
  text-align: left;
  letter-spacing: 0;
  font-size: 14px;
  color: #8E99AF;
}

.user-table tr th {
  text-align: left;
}

.user-popup {
  background: #fff;
  border-radius: 6px;
  padding-top: 5px;
}

#area-chart {
  width: 445px !important;
}

#pie-chart {
  width: 300px !important;
  margin-left: 68px;
}

.menu-row,
.mob-logo-row {
  display: none;
}

.search-btn {
  display: block;
}

tbody tr td {
  width: auto;
}

.checklist-pop-up h3 {
  text-align: left;
  font-size: 15px;
}

.pop-org {
  background: #fff;
  padding: 15px 10px;
  border-radius: 8px;
}

.labour-first-row .go-back {

  margin-top: 3px;
  float: left;
}

span.required {
  color: #ED1C24
}

.not-available {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  float: left;
  padding-left: 5px;
}

.react-confirm-alert-overlay {
  background: rgb(0, 0, 0, 0.3) !important;
  opacity: 1 !important;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  animation: none !important;
  z-index: 999999 !important;
}

.react-confirm-alert-body {
  font-family: 'Lato', sans-serif !important;
  box-shadow: 0px 6px 12px #0000000D !important;
  text-align: center !important;
}

.react-confirm-alert-body>h1 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
}

.react-confirm-alert-button-group {
  justify-content: center !important
}

.react-confirm-alert-button-group>button {
  font-size: 13px !important;
  text-transform: uppercase;
}

.react-confirm-alert-button-group button:nth-child(1) {
  background: #ddd !important;
  color: #000 !important;
}

.react-confirm-alert-button-group button:nth-child(2) {
  background: #4FB3FF !important;
  color: #fff !important;
}

.users-main-desk {
  padding: 15px 0;
}

.consumable-link {
  padding-bottom: 5px;
  background: #e6e4ff 0% 0% no-repeat padding-box;
}

.more-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 95
}

.custom-table .more-menu {
  z-index: inherit;
  top: 15px;
}

.more-menu span {
  display: inline-block;
  width: 10px;
  height: 15px;
  background: url("../img/icons/menu.svg") no-repeat;
  background-position: center;
  background-size: cover;
}

.more-menu ul {
  position: absolute;
  background: #fff;
  right: 0;
  top: 16px;
  width: 75px;
  padding: 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .1), 0 1px 3px 1px rgba(60, 64, 67, .1);
  border-radius: 8px;
  text-align: center;
  border: 1px solid #eee;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: .25s ease-out 0s;
}

.more-menu:hover ul {
  z-index: 99;
  opacity: 1;
  visibility: visible;
  transition: .25s ease-out 0s;
}

.more-menu ul li {
  padding: 3px 0;
  border-bottom: 1px solid #eee;
  line-height: 20px;
  font-size: 13px;
}

.more-menu ul li:last-child {
  border-bottom: 0;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  outline: 0 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #4EB1FF !important;
  outline: 0 !important;
}

.width-50 {
  width: 50px;
}

.width-75 {
  width: 75px;
}

.width-90 {
  width: 90px;
}

#multiselectContainerReact>div {
  padding: 0.438rem 0.75rem;
  min-height: calc(1.5em + 0.75rem + 2px);
}

#multiselectContainerReact>div.optionListContainer {
  padding: 0;
}

#multiselectContainerReact>div input {
  margin-top: 0;
}

#multiselectContainerReact span,
#multiselectContainerReact .optionContainer li {
  background: #847bff !important;
  color: #fff !important;
}

.page-heading {
  text-align: left;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
}

.page-heading span {
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  color: #666;
  text-transform: none;
}

.adv-checkbox {
  text-align: left;
  padding: 5px 20px;
}

.popup_inner .adv-checkbox {
  padding: 5px 0;
}

.adv-checkbox label {
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.adv-checkbox input[type="checkbox"],
.adv-checkbox input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.adv-checkbox .custom-checkbox,
.adv-checkbox .custom-radio {
  display: inline-block;
  vertical-align: top;
  margin: 0 12px 0 0;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid #ccc;
  position: relative;
}

.adv-checkbox .custom-checkbox {
  border-radius: 2px;
  background: #10B999;
  transition: box-shadow 180ms ease;
  box-shadow: inset 0 0 0 12px #fff;
  top: 0;
}

.adv-checkbox.blue-checkbox .custom-checkbox {
  background: #847bff !important;
}

.adv-checkbox .icon-check {
  width: 12px;
  height: 12px;
  background-size: cover;
  position: absolute;
  top: 2px;
  left: 2px;
  transform: scale(0);
  transition: all 180ms ease;
}

.adv-checkbox input[type="checkbox"]:checked+.custom-checkbox {
  border-color: #10B999;
  box-shadow: none;
}

.adv-checkbox.blue-checkbox input[type="checkbox"]:checked+.custom-checkbox {
  border-color: #847bff !important;
}

.adv-checkbox input[type="checkbox"]:checked+.custom-checkbox .icon-check {
  transform: scale(1);
}

.icon-check {
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20baseProfile%3D%22tiny%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2216%22%20height%3D%2216%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M13.483%201.992l-7.186%207.066-3.778-3.715-2.518%202.475%205.037%204.954%201.259%201.237%209.704-9.541-2.518-2.476z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  background-color: #4FB3FF !important;
}

/* media query starts here  */

@media screen and (max-width: 1199.9px) {

  .user-details-content {
    max-width: 100%;
    overflow-y: auto;
  }

  .user-image {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1024px) {

  html body .topbar-icon-wrapper {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mob-logo-row {
    text-align: left;
  }

  .mobile-menu-button {
    display: inline-block;
    margin-left: 15px;
  }

  .sidebar {
    margin-left: -250px;
    top: 82px;
    padding: 35px 0;
    height: calc(100vh - 82px);
    overflow-y: auto;
  }

  .sidebar.active-sidebar {
    margin-left: 0;
  }

  .logo-sidebar {
    display: none;
  }

  .inner-container {
    width: 100vw;
    padding: 95px 15px 30px;
    float: none;
    overflow: hidden;
  }

  .line-chart {
    box-shadow: none;
    display: block;
    margin: 0 auto;
  }

  .row-class-charts {
    padding: 0;
  }

  .search-btn {
    display: none;
  }

  .menu-row,
  .mob-logo-row {
    display: block !important;
  }

  .search-btn {
    display: none;
  }

  #area-chart {
    width: 300px !important;
    margin: 0 auto;
  }

  #pie-chart {
    width: 250px !important;
    margin-left: 0;
    margin: 0 auto;
  }

  .line-chart,
  .doughnout-chart,
  .pie-chart {
    margin: 15px;
  }

  .line-chart h5,
  .doughnout-chart h5 {
    text-align: center;
  }

  #area-chart canvas {
    width: 374 !important;
  }

  .charts-div h4,
  .charts-div p {
    text-align: center !important;
  }

  #chart-1,
  #chart-2,
  #chart-3 {
    margin: 0 auto;
  }

  .mobile-menu-atcl {
    display: block;
  }

  .topbar-mob-menu {
    background: #fff;
    padding: 15px;
    border-bottom: 2px solid #efefef;
  }

  .user-details-content-modal {
    top: 135%;
    right: 8%;
  }

  .mt-2.top-bar-main-class {
    margin: 0 !important;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9999;
  }

  .form-sent-sidebar {
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: auto;
    background: #fff;
    box-shadow: 0px 3px 12px #0000001A;
    z-index: 1;
    border-radius: 6px;
    margin-bottom: 20px;
  }

  .send-req-tabs ul {
    padding-top: 15px !important;
  }

  .popup_inner {
    max-width: 100%;
    height: 100%;
    max-height: 444px;
    overflow-y: auto;
    margin-top: 200px;
  }

  .custom-tabs-div {
    width: auto;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

  }

  .org-mem-details {
    padding: 10px;
  }

  .inventory-inner-container {
    width: 100% !important;
  }

  .super-admin-tabs {
    list-style: none;
    margin: 0 0 20px;
    padding: 5px 0 0;
    text-align: left;
    float: left;
    margin: 0;
    padding: 0;
    display: grid;
    grid-gap: 5px !important;
    grid-template-columns: repeat(6, calc(50% - 56px)) !important;
    margin-top: 5px;
  }

  .add-btn-green {
    margin-bottom: 10px;
  }

  .tab-first {
    font-size: 13px;
  }

  .more-menu {
    position: absolute;
    top: 21px;
    right: 7px;
    cursor: pointer;
    z-index: 95;
  }

  .add-labour-mem {
    max-width: 45%;
  }

  .add-labour-mem {
    max-width: 71%;
  }

  .m-inven-btn.edit-button {
    /* float: left !important; */
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .custom-form-control {
    width: 200px !important;
  }

  .send-btn {
    width: auto;
  }
}

@media screen and (max-width: 767px) {
  .auth-wrapper {
    min-width: 320px;
    padding: 25px;
    margin-top: 100px;
  }

  .vertical-center-container {
    display: flex;
    -ms-justify-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .table {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    overflow-x: auto;
  }

  .consumable-blocks {
    position: relative;
    background: #fff;
    padding: 15px;
    margin-bottom: 30px;
    border-bottom: 4px solid #847bff !important;
    border-radius: 5px;
    box-shadow: 0px 0px 3px #bbb;
    transition: 0.15s ease-in box-shadow;
    margin-top: 20px;
  }

  .comm-border {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E4E4E4;
    margin-top: 20px;
  }
}

@media screen and (max-width: 480px) {

  .user-details-content-modal {
    position: absolute;
    top: 87%;
    top: 150% !important;
    right: 0 !important;
    width: 92vw !important;
  }

  .add-labour-mem {
    max-width: 100%;
  }

  .edit-button {
    float: left !important;
    margin-top: 0 !important;
    margin-bottom: 16px !important;
  }

  .custom-tabs {
    list-style: none;
    margin: 5px 0 11px;
    padding: 5px 0 0;
    text-align: left;
    float: left;
  }

  .page-header h1 {
    font-size: 17px !important;
    line-height: 35px;
  }

  .entry-save,
  .entry-close {
    float: left;
    margin-left: 0;
    margin-right: 10px;
    margin-top: 5px;
  }

  .popup-title {
    font-size: 16px;
  }

  .users-main-desk {
    padding: 10px 0;
  }

  .consumable-blocks {
    margin-bottom: 15px;
    margin-top: 15px;

  }

  .add-btn {
    margin-bottom: 15px;
  }

  .checklist-weeks,
  .checklist-month {
    float: left;
    margin-left: 0 !important;
    margin-top: 10px;
    margin-right: 10px;
  }

  .org-mem-page-link .prev {

    padding-left: 41px;
    font-size: 14px;
  }

  .prev::before {
    width: 25px;
    height: 25px;
    content: " ";
    background: url("../../assets/img/arrow.svg") no-repeat;
    background-size: 25px 22px;
    position: absolute;
    left: 11px;
  }

  .org-mem-page-link .next {
    padding-right: 45px;
    font-size: 14px;
  }

  .next::before {
    width: 22px;
    height: 22px;
    content: " ";
    background: url("../../assets/img/right-arrow.svg") no-repeat;
    background-size: 20px 19px;
    position: absolute;
    right: 14px;
  }

  .consumable-blocks .consumable-link-btn.consumable-arrow {
    position: absolute;
    bottom: 24px;
    right: 14px;
    top: auto;
  }

  .consumable-blocks .consumable-link-btn {
    position: absolute;
    width: auto;
    top: -4px;
    right: 0;
  }

  .right-float-edits img,
  .right-float-edits button {
    float: right;
  }

  .super-admin-tabs {
    grid-gap: 5px !important;
    grid-template-columns: repeat(6, calc(50% - 41px)) !important;
    margin-top: 5px;
  }

  .user-image-edit {
    height: 200px;
    width: 200px;
  }

  .custom-table {
    border-radius: 0;
  }

  .row-class-charts {
    margin-top: 0;
  }

  .custom-tabs li {
    margin: 0 10px 10px 0;
  }

  .consumable-link {
    margin-right: 15px;
  }

  .custom-table tr td img {
    width: 12px;
  }

  .add-labour-mem {
    max-width: 80%;
  }

  .green-save {
    margin-left: 0 !important;
  }

  .edit-button {
    margin-top: 15px !important;
  }

  .item-data-display h3 {
    text-align: center;
  }

  .item-data-display p {
    text-align: center;
  }

  .order-item-name {
    text-align: left;
  }
}

@media screen and (max-width: 360px) {
  .auth-wrapper {
    min-width: 313px;
    margin-top: 100px;
  }

  .page-header h1 {
    font-size: 12px !important;
    line-height: 35px;
  }

}

.month-select .react-datepicker__header,
.month-select .react-datepicker__navigation {
  display: none !important;
}

.month-select .react-datepicker-wrapper {
  width: 110px;
  float: right;
  height: 40px;
}

.month-select .react-datepicker__input-container {
  height: 40px !important;
}

.location-td {
  padding: 15px !important;
  width: 18%;
}

.project-save {
  background: #fff !important;
  border: 1px solid #847bff !important;
  color: #847bff !important;
}

.project-close {
  border: 1px solid #847bff !important;
  background: #847bff !important;
  color: #fff !important;
}