.project-image {
  height: 150px !important;
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  background: #F2F1FF url("../../../assets/img/project-sample.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}

.main-project-div {
  box-shadow: 0px 0px 3px #bbb;
  border-radius: 5px;
  margin: 15px 0;
  min-height: 260px;
  border-bottom: 4px solid #847BFF !important;
  transition: 0.15s ease-in box-shadow;
}

.main-project-div:hover {
  box-shadow: 0px 2px 10px #bbb;
}

.main-project-div.add-project {
  border-bottom: 0 !important;
  box-shadow: 0px 0px 1px #bbb;
  background: #EBECFB;
  position: relative;
}

.add-project-button {
  cursor: pointer;
  border: 0;
  outline: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/plus-violet.svg") no-repeat;
  background-size: 50px;
  background-position: center;
}

.project-single-meta-data {
  background: #fff;
  padding: 10px;
  position: relative;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #000000;
  padding-top: 5px;
  line-height: 30px;
}

.main-project-div .overflow-ellipsis {
  text-align: left;
  font-weight: 600;
  padding: 0;
  max-width: calc(100% - 20px);
}

.custom-table .overflow-ellipsis {
  max-width: 150px;
}

.project-single-meta-data .goto-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.pro-date {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  color: #8E99AF;
  font-size: 12px;
}

.pro-locs {
  text-align: left;
  font: Bold 12px/15px Lato;
  letter-spacing: 0;
  color: #8E99AF;
}

.charts-desc {
  padding-bottom: 15px;
}

.project-progress-charts img {
  max-width: 100%;
  border-radius: 5px;
}

.check-list-head {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #000000;
  margin: 0 0 15px;
}

.checklist-table {
  text-align: left;
}

.bold-head {
  font-weight: 600;
}

.checklist-table td {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  color: #000000;
  padding: 6px 30px;
  border-bottom: 1px solid #E4E4E4;
  border-top: 0;
}

.checklist-table thead {
  border-radius: 10px 10px 0 0;
}

.custom-radio-btn {
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  width: 16px;
  height: 16px;
}

.custom-radio-btn input {
  position: absolute;
  left: -9999px;
}

.custom-radio-btn input:checked+span:before {
  box-shadow: inset 0 0 0 0.32em #847bff;
}

.custom-radio-btn span {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 99em;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
}

.custom-radio-btn span:before {
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #847bff;
}

.consumable-div {
  height: 100px;
  background: #ECF6FF;
}

.consumable-div .custom-btn {
  width: auto;
  height: 35px;
  padding: 0 20px;
  border-radius: 5px;
  outline: 0 !important;
  font-weight: 600;
  box-shadow: 0px 6px 6px #0000000D;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 34px;
  transition: background 0.2s linear;
  margin: 10px;
  float: right;
}

.consumable-div .custom-btn.goto-btn {
  background: #fff url(../../../assets/img/icons/arrow-right.svg) no-repeat;
  background-size: 16px;
  background-position: center;
  border: 0;
  box-shadow: 0 0 0;
  transition: background 0.3s ease-out;
  border-radius: 5px 0px 4px 0px;
}

.projects-button:hover {
  background-color: #847BFF !important;
  background-size: 16px;
  background-position: center;
  border: 0;
  box-shadow: 0 0 0;
  transition: background 0.3s ease-out;
  border-radius: 5px 0px 4px 0px;
}


.member-img {
  width: 48px;
  height: 48px;
  background: #e6e4ff 0% 0% no-repeat padding-box;
  border-radius: 48px;
  margin-top: 10px;
  line-height: 48px;
  text-align: center;
  color: #847bff;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 1px;
}

.member-img.labour-member-img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-top: 0;
  line-height: 40px;
  font-size: 14px;
}

.comm-border {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
}

.org-mem-details {
  padding: 10px 0;
  margin-bottom: 5px;
}

.org-mem-details p {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  color: #8E99AF;
  margin-bottom: 1px;
}

.org-mem-details h5 {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  color: #000000;
}

.org-mem-row {
  margin: 0 !important;

}

.org-mem-page-link {
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 30px #D1D5DF80;
  border: 1px solid #EDF0F7;
  border-radius: 0px 0px 20px 20px;
}

.org-mem-page-link ul {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
}

.org-mem-page-link li {
  width: 33.333%;
  float: left;
  margin: 20px 0 0 0;
}

.org-mem-page-link .prev {
  float: left;
  padding-left: 65px;
  font-size: 16px;
  letter-spacing: 0;
  color: #BDC4D5;
  position: relative;
}

.org-mem-page-link .next {
  float: right;
  padding-right: 65px;
  font-size: 16px;
  letter-spacing: 0;
  color: #BDC4D5;
  position: relative;
}

.active-slide {
  font-size: 16px;
  letter-spacing: 0;
  color: #858997;
}

.org-mem-page-link .next:hover,
.org-mem-page-link .prev:hover {
  color: #4FB3FF;
  text-decoration: none;
}

.prev::before {
  width: 25px;
  height: 25px;
  content: " ";
  background: url(../../../assets/img/arrow.svg ) no-repeat;
  background-size: 25px 26px;
  position: absolute;
  left: 34px;
}

.next::before {
  width: 22px;
  height: 22px;
  content: " ";
  background: url(../../../assets/img/right-arrow.svg) no-repeat;
  background-size: 22px 22px;
  position: absolute;
  right: 34px;
}

.org-mem-page-link .next:hover:before {

  width: 22px;
  height: 22px;
  content: " ";
  background: url(../../../assets/img/right-arrow-blue.svg) no-repeat;
  background-size: 22px 22px;
  position: absolute;
  right: 34px;
}

.org-mem-page-link .prev:hover:before {
  width: 25px;
  height: 25px;
  content: " ";
  background: url(../../../assets/img/arrow-blue.svg) no-repeat;
  background-size: 25px 26px;
  position: absolute;
  left: 34px;
}

/* /................pop-up styles.................../ */

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0, 0, 0, 0.3) 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 999;
  overflow-y: auto;
}

.entry-select {
  width: 100%;
}

.entry-save,
.entry-close {
  border: 1px solid #4EB1FF;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: uppercase;
  float: right;
  width: 80px;
  line-height: 30px;
  padding: 0;
  outline: 0;
}

.entry-save {
  background: #4FB3FF 0% 0% no-repeat padding-box;
  border: 1px solid #4EB1FF;
  color: #FFFFFF;
  margin-left: 10px;
  padding: 1px;
}

.entry-close {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #4FB3FF;
}

.row-entry-head {
  margin-top: 3px;
  border-bottom: 1px solid #E4E4E4;
  padding-bottom: 16px;
}

.popup_inner .mob-no-input {
  padding: 9px 5px;
}

.checklist-weeks,
.checklist-month {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding:  8px 20px 8px 18px;;
  float: right;
  margin-bottom: 10px;
  text-align: center;
}

.checklist-design {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 8px 30px 8px 10px;
  float: left;
  margin-bottom: 10px;
  width: 80%;
  margin-top: 10px;
}

.checklist-weeks {
  margin-left: 30px;
}

.labour-year {
  padding: 7px 10px !important;
}

.right-selection {
  padding: 0 !important;
}
.year{
  text-align: initial;
}
.checklist-weeks:focus,
.checklist-month:focus,
.year-name:focus {
  outline: 0;
}

.year-name {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 8px 10px;
  width: 38%;
  text-align: center;
  left: -55px;
}

.custom-table tr td,
.custom-table .abour-th th {
  vertical-align: middle;
}

.custom-table tr td img {
  width: 15px;
}

.custom-table tr td .name-img {
  width: 50px;
}

.uppercase {
  text-transform: uppercase;
}

.labour-link {
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  float: left;
}

.labour-link:hover {
  background: #9d95ff 0% 0% no-repeat padding-box;
}

.labour-link img {
  width: 18px;
  height: 18px;
}

.labour-table tbody td p {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  color: #000000;
  margin-bottom: 0;
}

.labour-th p {
  margin-bottom: 0;
  vertical-align: middle;
  font-size: 13px;
  text-align: center !important;
}

.inline-display input {
  width: 110px;
  float: right;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
}

.inline-display input:focus {
  outline: none;
}

.inline-display .checklist-weeks,
.inline-display .checklist-month {
  margin-left: 0;
  margin-right: 10px;
}

.labour-details {
  padding: 5px 31px;
  padding-top: 15px;

}

.labour-details p {
  text-align: left !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #8E99AF !important;
  margin-bottom: 0;
}

.labour-details h5 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #000000;
  padding-bottom: 7px;
}

.go-back {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #4EB1FF;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 11px;
  letter-spacing: 0;
  color: #4FB3FF;
  text-transform: uppercase;
  margin-right: 10px;
}

.total-wage-div {
  background: #fff;
  padding: 5px 12px;
}

.labour-table-detail {
  background: #FFF;
  padding: 15px !important;
  border-radius: 0px 0px 10px 10px;
}

.labour-popup-table {
  padding: 0 10px 10px;
}

.labour-first-row {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #E4E4E4;
  border-radius: 10px 10px 0px 0px;
  padding: 12px 29px;
}

.labour-popup-table table th {
  padding: 15px 20px !important;
}

.labour-popup-table table tr td {
  padding: 5px 20px !important;
}

.total-wage-div p {
  text-align: left !important;
  font-size: 12px;
  letter-spacing: 0;
  font-weight: 500 !important;
  color: #8E99AF !important;
  margin-bottom: 2px;
}

.total-wage-div h4 {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0;
  color: #000000;
  font-weight: 600;
  line-height: 32px;
}

.total-div {
  border-bottom: none !important;
  margin-top: 10px;
}

.total-div p,
.total-div h4 {
  color: #847bff !important;

}

.labour-popup-table table {
  border: 1px solid #9e9e9e36;
  box-shadow: 0 0 0;
  margin-bottom: 0 !important;
}

.pop-consum-register {
  padding: 15px 22px;
}

.popup-title {
  text-align: left;
  font-size: 18px;
  letter-spacing: 1px;
  color: #000000;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  line-height: 32px;
}

.pop-consum-register input,
.pop-consum-register select {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
}

.pop-consum-register label {
  text-align: left;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
  color: #000000;
  font-weight: 600;
}

.pop-consum-register input:focus,
.pop-consum-register select:focus {
  outline: 0;
}

.consumable-blocks {
  position: relative;
  background: #fff;
  padding: 15px;
  margin-bottom: 30px;
  border-bottom: 4px solid #847bff !important;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #bbb;
  transition: 0.15s ease-in box-shadow;
}

.consumable-blocks:hover {
  box-shadow: 0px 2px 10px #bbb;
}

.consumable-blocks p {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
  color: #8E99AF;
  margin-bottom: 2px;
}

.consumable-blocks h5 {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.consumable-link-btn {
  background: url(../../../assets/img/icons/arrow-right.svg) no-repeat;
  background-size: 18px;
  background-position: center;
  border: 0;
  transition: background 0.3s ease-out;
  border-radius: 5px 0px 4px 0px;
}

.consumable-blocks .consumable-link-btn {
  position: absolute;
  width: auto;
  top: 0;
  right: 0;
  height: 35px;
  padding: 0 20px;
  display: inline-block;
  border-radius: 5px;
  outline: 0 !important;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 34px;
  margin: 6px;
}

.consumable-blocks .consumable-link-btn.consumable-arrow {
  position: absolute;
  width: auto;
  top: 0;
  right: 14px;
  height: 35px;
  padding: 0 20px;
  display: inline-block;
  border-radius: 5px;
  outline: 0 !important;
  text-decoration: none !important;
  text-transform: uppercase;
  line-height: 34px;
  margin: 6px;
}

.consumable-blocks:hover .consumable-link-btn {
  background: url(../../../assets/img/icons/arrow-right-blue.svg) no-repeat;
  background-size: 18px;
  background-position: center;
  border: 0;
  border-radius: 5px 0px 4px 0px;
}

.clickable-block {
  outline: 0 !important;
  text-decoration: none !important;
}

.popup_inner_check {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 10%;
  margin: auto;
  border-radius: 20px;
  background: white;
  padding: 15px 10px;

}

.task-h5 {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
  color: #000000;
  text-transform: uppercase;
  font-weight: 600;
  padding-top: 20px;
  margin-bottom: 0;
}

.checklist-head label {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  color: #000000a6;
  padding-top: 17px;
  padding-bottom: 15px;
}

.add-task-btn {
  background: #F2F1FF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  float: left;
  border: 0;
  vertical-align: middle;
  width: 100%;
  padding: 8px;
  margin-top: 14px;
}

.add-task-btn img {
  width: 16px;
  height: 16px;
}

.add-task-btn p {
  margin-bottom: 0;
  color: #10b999;
  padding: 1px 0;
}

.consumable-direct {
  position: relative;
}

.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.react-datepicker-popper {
  z-index: 10 !important
}

.react-datepicker-wrapper {
  display: block !important;
}

.custom-file,
.custom-file-input,
.custom-file-label {
  margin: 0 !important;
  height: 38px !important;
}

.custom-file-label {
  line-height: 1.8 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-file-label::after {
  height: 36.5px !important;
  line-height: 1.8 !important;
}

.custom-file-input:focus~.custom-file-label {
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25) !important
}

.form-group label {
  font-weight: 600;
  text-align: left;
}

.lab-allow-btn {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  opacity: 1;
  padding: 5px;
  line-height: 20px !important;
}

.row-bb {
  border-bottom: 1px solid #E4E4E4;
}

.total-div p {
  margin-bottom: 0;
}

.labour-btn-adds {
  background: #ECF6FF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  opacity: 1;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #4FB3FF;
  text-transform: uppercase;
  margin: 0 auto
}

.adv-checkbox .custom-checkbox {
  background: #4FB3FF !important;
}

.adv-checkbox input[type="checkbox"]:checked+.custom-checkbox {
  border-color: #4FB3FF !important;
}

.track-labour {
  margin-top: 1rem;
}

.m-0 {
  margin: 0;
}

.m-t-10 {
  margin-top: 10px;
}

.labour-date-input {
  width: 150px;
}

.ot-hours-input {
  width: 75px;
  margin: 0 auto;
}

.custom-btn.add-user-btn {
  border: 1px solid #ff98d6 !important;
  color: #ff98d6 !important;
}

.custom-btn.add-user-btn:hover {
  background: #ff98d6 !important;
  color: #fff !important;
}

.entry-save.project-save {
  background: #847bff !important;
  border: 1px solid #847bff !important;
  color: #fff !important;
}

.entry-close.project-close {
  border: 1px solid #847bff !important;
  color: #847bff !important;
  background: #fff !important;
}

.vehicle-input {
  padding-top: 10px;
  padding-bottom: 10px;
}


.more-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 95;
}
.edit-menu{
  margin-top: 7px;
}

.org-inventory ul {
  position: absolute;
  background: #fff;
  right: 0;
  top: 16px;
  width: 75px;
  padding: 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .1), 0 1px 3px 1px rgba(60, 64, 67, .1);
  border-radius: 8px;
  text-align: center;
  border: 1px solid #eee;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: .25s ease-out 0s;
}

.expand-cell-header,
th.sortable,
.expand-cell {
  cursor: pointer;
}

.table thead th:focus {
  outline: 0;
}

.display-hide {
  visibility: hidden;
}

.caret,
.caret-4-asc,
.caret-4-desc {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  margin-top: -2px;
  vertical-align: middle;
  border-top: 5px dashed;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.caret-4-asc {
  content: "";
  border-top: 0;
  border-bottom: 5px dashed;
  border-bottom: 5px solid;
}

.reset-expansion-style {
  background: #f4f5f6;
}

.custom-edit-button {
  display: inline-block;
  width: 36px;
  height: 36px;
  background: #847bff url(../../../assets/img/icons/edit-white.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  border: 1px solid #847bff;
  border-radius: 5px;
  outline: 0;
  margin-right: 10px;
}

.custom-delete-button {
  display: inline-block;
  width: 36px;
  height: 36px;
  background: #847bff url(../../../assets/img/icons/delete-white.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  border: 1px solid #847bff;
  border-radius: 5px;
  outline: 0;
}

.pagination {
  float: right;
}

.page-link {
  color: #847bff !important;
}

.page-item.active .page-link {
  background-color: #847bff !important;
  border-color: #847bff !important;
  color: #fff !important;
}

.page-link:focus {
  box-shadow: 0 0 0 !important;
}

.total-button {
  background-color: rgba(132, 123, 255, 0.25);
  color: #000000a6;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  float: right;
  padding: 0 15px;
  line-height: 40px;
  margin-right: -15px;
}

.total-style {
  margin: 0;
}

.amout-style {
  margin-top: 0;
  margin-bottom: 0rem;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bolder;
}

.pop-up-custom-width {
  display: table;
}

.text-alig-end {
  text-align: end;
}

.export-btn {
  border: 1px solid#847bff;
  background: #847bff;
  border-radius: 8px;
  color: #fff !important;
  text-align: center;
  float: right;
  line-height: 35px;
  margin-right: 30px;
}

.react-datepicker-popper {
  width: 330px;
}

.react-datepicker__header--time {
  padding: 20px 5px 21px !important;
}

.not-editable-box{
  width: 75px;
  margin: 0 auto;
  text-align: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  opacity: 1;
  padding: 5px;
  line-height: 20px !important;
}