.super-admin-tabs {
        list-style: none;
        margin: 0 0 20px;
        padding: 5px 0 0;
        text-align: left;
        float: left;
        margin: 0;
        padding: 0;
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(6, calc(50% - 56px));
        margin-top: 5px;
}

.super-admin-back-button:hover {
        background: #10B999 url(../../../assets/img/icons/arrow-back-white.svg) no-repeat;
        background-size: 16px;
        background-position: center;
        border-color: #10B999;
}

.super-admin-tabs li:hover {
        background: #10B999 0% 0% no-repeat padding-box;
        border: 1px solid #10B999;
        border-radius: 5px;
        color: #fff;
}

.super-admin-tabs li:hover,
.super-admin-tabs li.react-tabs__tab--selected,
.super-admin-tabs li.active-tab {
        background: #10B999 0% 0% no-repeat padding-box;
        border: 1px solid #10B999;
        border-radius: 5px;
        color: #fff;
}

.add-btn-green {
        width: 100%;
        height: 40px;
        padding: 0 8px;
        display: inline-block;
        background: #10B999;
        border: 1px solid #10B999;
        border-radius: 8px;
        color: #fff !important;
        outline: 0 !important;
        font-weight: 500;
        box-shadow: 0px 6px 6px #0000000D;
        text-decoration: none !important;
        text-transform: uppercase;
        line-height: 34px;
        transition: background 0.2s linear;
        margin-top: 10px;
}

.admin-checklist-div,
.units-div {
        padding: 40px;
}

.settings-edit-button {
        background: url(../../../assets/img/edit-green.svg) no-repeat;
}

.settings-delete-button {
        background: url(../../../assets/img/delete-green.svg) no-repeat;
}

.settings-toggle-button {
        background: url(../../../assets/img/down-black.svg) no-repeat;
        background-size: 14px !important;
}

.active-accordion .settings-toggle-button {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
}

.settings-btn {
        width: 32px;
        height: 32px;
        margin: 8px 6px;
        background-size: 32px;
        background-position: center;
}

.tab-first {
        text-align: left !important;
        font-size: 16px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 600 !important;
        opacity: 1;
        margin: 12px 0;
        padding: 0 15px !important;

}
.titleContent {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
.card-header {
        background-color: #fff !important;
        padding: 0 !important;
}

.card-body {
        padding: 0rem !important;
}

.accor-body {
        border-bottom: 1px solid #E4E4E4;
        padding-top: 10px;
        display: block;
        height: 100%;
        max-height: 500px;
        overflow-y: scroll;
}

.accor-body p {
        text-align: left;
        text-align: left;
        margin-bottom: 8px;
}

.checklist-item-desc {
        padding: 0 15px;
        text-align: left;
}

.accor-body input {
        margin-top: 5px;
}

.tab-second {
        text-align: left;
        font-size: 16px;
        letter-spacing: 0px;
        color: #000000;
        font-weight: 600;
        opacity: 1;
        padding: 10px 0 0 15px;
}

.right-float-edits img {
        padding: 10px;
        cursor: pointer;
}

.right-float-edits .toggle-arrow {
        width: 31px;
        height: 28px;
}

.user-role-div {
        padding: 40px;
}

.entry-close-super {
        border: 1px solid #10B999;
        border-radius: 5px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        text-transform: uppercase;
        float: right;
        width: 80px;
        line-height: 30px;
        padding: 0;
        outline: 0;
}

.green-save {
        background: #10B999 0% 0% no-repeat padding-box;
        border: 1px solid #10B999;
        color: #FFFFFF;
        margin-left: 10px;
}

.green-close {
        color: #10b999;
        border: 1px solid #10b999;
}

.checklist-item-entry {
        position: relative;
}

.checklist-item-entry .delete-btn {
        position: absolute;
        right: 4px;
        top: 4px;
        width: 30px;
        height: 30px;
        border: none;
        padding: 0;
        border-radius: 0.25rem;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        transition: all ease-out 0.2s;
        background: #F2F1FF url(../../../assets/img/icons/delete-black.svg) no-repeat;
        background-size: 16px;
        background-position: center
}

.checklist-item-entry:hover .delete-btn {
        visibility: visible;
        opacity: 1;
        z-index: 1
}

.no-data {
        padding: 0 15px;
        color: #666;
}

.designation-details {
        padding: 5px 20px;
}

.designation-details span {
        display: inline-block;
        min-width: 80px;
}

.adv-checkbox .custom-checkbox {
        background: #10B999 !important;
}

.adv-checkbox input[type="checkbox"]:checked+.custom-checkbox {
        border-color: #10B999 !important;
}