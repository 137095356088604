.user-h1 {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    line-height: 35px;
    text-align: left;
}

.user-table {
    margin-top: 15px;
    text-align: left;
}

.user-table thead {
    font-weight: 600;
    border-top: 0;
}

.user-table thead tr>td {
    border-top: 0;
}

.add-u-head {
    border-bottom: 1px solid #E4E4E4;
    padding: 10px 27px;
}

.active-indication,
.inactive-indication {
    width: 16px;
    height: 16px;
    border-radius: 20px;
    background: #ff98d6;
    display: inline-block;
}

.inactive-indication {
    background: #F03E46;
}

.width-125 {
    width: 125px;
}

.delete-btn {
    width: auto;
    height: 30px;
    padding: 0 20px;
    display: inline-block;
    background: #fff;
    border-color: transparent;
    outline: 0 !important;
}

.delete-btn.active {
    background: url(../../../assets/img/icons/delete-black.svg) no-repeat;
    background-size: 16px;
    background-position: 50% 40%;
    cursor: pointer;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: all ease-out 0.2s;
}

.delete-btn.disabled {
    cursor: initial;
}

.user-table tr:hover .delete-btn {
    opacity: 1;
    z-index: 1;
    visibility: visible;
}
.entry-save.user-save {
    background: #FF98D6 !important;
    border: 1px solid #FF98D6 !important;
}
.entry-close.user-close {
    border: 1px solid #FF98D6 !important;
    color: #ff98d6 !important;
}
