/* .inventory-inner-container {
    width: calc(100% - 335px);
} */

.single-meta-data-detail {
    padding: 20px;
}

.single-meta-data-detail .overflow-ellipsis {
    font-size: 24px;
    font-weight: 700;
}

.single-meta-data-detail span {
    text-align: center;
    font-size: 12px;
    letter-spacing: 0;
    color: #8E99AF;
}

.single-meta-data-detail span.unit-text {
    color: #888;
}

.item-data-display h3 {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px;
    padding: 0 10px;
}

.item-data-display p {
    line-height: 23px;
    padding: 0 10px;
    margin-bottom: 0;
    font-size: 16px;
    color: #555;
}

.items-details {
    padding: 15px;
    background: #fff;
    margin-top: 23px;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    box-shadow: 0px 6px 12px #0000000D;
    text-align: left;
}

.add-more-inventory {
    background: #fff;
    border: 1px solid #4FB3FF;
    color: #4FB3FF;
    float: left;
    padding: 7px;
    width: 250px;
    border-radius: 5px;
    margin: 0 0 15px 10px;
}

.add-more-inventory:focus {
    outline: 0;
}

.send-req-tabs {
    text-align: left;
    width: 100%;
    margin-top: 30px;
}

.send-req-tabs ul {
    padding: 0 35px;
    margin-bottom: 0;
    list-style: none;
    border-bottom: 1px solid #eee;
}

.send-req-tabs ul li {
    outline: 0;
    font-size: 20px;
    font-weight: bold;
    color: #8E99AF;
    text-transform: uppercase;
    padding: 0 10px 15px;
    display: inline-block;
    cursor: pointer;
    position: relative;
}

.send-req-tabs ul li.react-tabs__tab--selected {
    color: #000;
    font-size: 20px;

}

.send-req-tabs ul li.react-tabs__tab--selected:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -2px;
    left: 0;
    background: #4FB3FF;
}

.send-req-tabs .react-tabs__tab-panel {
    max-height: calc(100vh - 85px);
    overflow-y: auto;
}

.send-btn:hover {
    color: #fff;
}

.active-panel {
    color: #000 !important;
    border-bottom: solid 3px #4FB3FF !important;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: uppercase;
    border: none;
    background: #fff;
    font-family: 'Lato', sans-serif;
    outline: 0;
    padding: 16px 0;
    padding-right: 31px;
}

.button-request,
.button-send {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    color: #8E99AF;
    text-transform: uppercase;
    border: none;
    background: #fff;
    font-family: 'Lato', sans-serif;
}

.send-req-tabs button:focus {
    outline: 0;
}

.edit-button {
    float: right;
    margin-top: 6px;
    margin-left: auto;
}

.label-sub-text {
    font-weight: normal;
    color: #888;
}

.item-location-button {
    width: 36px;
    height: 36px;
    border: none;
    padding: 0;
    border-radius: 5px;
    float: left;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all ease-out 0.2s;
}

.location-edit-button {
    background: #8E99AF url(../../../assets/img/icons/edit-white.svg) no-repeat;
    background-size: 19px;
    background-position: center;
}

.save-button {
    background: #8E99AF url(../../../assets/img/icons/save-white.svg) no-repeat;
    background-size: 18px;
    background-position: center;
}

.comment-button {
    background: #4FB3FF url(../../../assets/img/icons/comment-white.svg) no-repeat;
    background-size: 19px;
    background-position: center
}

.delete-button {
    background: #F2F1FF url(../../../assets/img/icons/delete-black.svg) no-repeat;
    background-size: 19px;
    background-position: center;
}

.comment-button,
.item-locations-table tbody tr:hover .item-location-button {
    visibility: visible;
    opacity: 1;
    z-index: 1
}

.location-list {
    max-width: 300px
}

.comment-btn-item {
    width: 24px;
    height: 24px;
    border: none;
    padding: 0;
    border-radius: 5px;
    float: right;
    margin-left: 8px;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all ease-out 0.2s;
}

.comment-edit-btn {
    background: #aaa url(../../../assets/img/icons/edit-white.svg) no-repeat;
    background-size: 16px;
    background-position: center;
}

.comment-delete-btn {
    background: #eee url(../../../assets/img/icons/delete-black.svg) no-repeat;
    background-size: 16px;
    background-position: center;
}

.single-item:hover .comment-edit-btn,
.single-item:hover .comment-delete-btn {
    visibility: visible;
    opacity: 1;
    z-index: 1;
}

.single-item {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    text-align: left;
    padding: 15px;
    margin-top: 15px;
}

.single-item p {
    margin-bottom: 0;
    line-height: 22px;
}

#order-type {
    text-transform: capitalize;
}

.single-item .meta-details {
    color: #8E99AF;
    line-height: 25px;
    margin-bottom: 10px;
}

.activity-item .meta-details {
    margin-bottom: 5px;
}

.single-item .meta-details span {
    background: #4fb3ff1c;
    color: #0093FF;
    font-weight: 500;
    padding: 2px 6px;
    border-radius: 4px;
    line-height: 20px;
    margin-right: 5px;
    display: inline-block;
}

.single-item .meta-details .activity-location {
    float: right;
    margin-right: 0;
}

select:focus,
select:active {
    outline: 0 !important;
}

html body .item-locations-table th {
    text-align: left;
    font-size: 12px;
    letter-spacing: 0;
    color: #8E99AF;
    text-transform: uppercase;
}

.w-50 {
    width: 50%;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.max-width-200 {
    max-width: 200px
}

.inventory-location {
    display: block;
    width: 235px;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.span-location {
    background: #f2f1ff;
    margin: 2px 2px;
    padding: 2px 8px;
    border-radius: 4px;
    float: left;
}

.order-loc-from {
    background: #F2F1FF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 8px;
}

.order-loc-to {
    background: #F2F1FF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 8px 15px;
}

.ord-recv {
    text-align: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: #4FB3FF;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #4EB1FF;
    border-radius: 8px;
    padding: 8px 15px;
}

.in-route-order {
    background: #FEF5EA 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 8px 15px;
}

.order-location-qty {
    color: #999;
    font-weight: 300;
    font-size: 13px;
    margin-left: 2px;
}

.blue {
    color: #847BFF;
}

.orange {
    color: #FFA43A;
}

.red {
    color: #F01C24;
}

.orders-head th p {
    width: 140px;
    margin-bottom: 0;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    display: none;
}

.order-item-name {
    float: left;
    font-size: 20px;
    font-weight: 600;
}

.order-item-name-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.selection-div {
    float: right;
}

.selection-div span {
    text-align: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: #8E99AF;
    padding-right: 10px;
}

.selection-div select {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
    padding: 6px 8px;
}

.comment-item-name {
    float: left;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 18px;
}

.items-span {
    background: #E8EBF0;
    color: #4FB3FF;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 4px 5px;
    border-radius: 3px;
    font-weight: 500;
    margin-left: 3px;
}

.activity-date {
    font-size: 12px;
    font-weight: 600;
}

.small-blue-text {
    color: #4FB3FF;
    font-size: 0.75rem
}

#item-description {
    height: 80px;
}

.sub-heading {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #8E99AF;
    margin-bottom: 15px;
}

.item-btn {
    border: 0;
    font-size: 24px;
    line-height: 38px;
    width: 42px;
    height: 38px;
    float: left;
    border-radius: 5px;
    margin-right: 10px;
    background: #F2F1FF;
    transition: all ease-out 0.2s;
}

.add-item-btn:hover {
    color: #fff;
    background: #4FB3FF;
}

.delete-item-btn {
    background: #F2F1FF url(../../../assets/img/icons/delete-black.svg) no-repeat;
    background-size: 19px;
    background-position: center;
}

.delete-item-btn:hover {
    background: #ccc url(../../../assets/img/icons/delete-black.svg) no-repeat;
    background-size: 19px;
    background-position: center;
}

.error-label {
    margin: -15px -15px 0;
    text-align: left;
    padding: 5px 30px;
    background: #C8E8FF;
    border-radius: 4px 4px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

.error-label span {
    display: inline-block;
    background: #ECF7FF;
    border-radius: 4px;
    color: #0093FF;
    padding: 3px 7px;
    font-size: 13px;
    font-weight: 500;
    margin-left: 5px;
}

.width-200 {
    width: 200px;
}

.width-100 {
    width: 100px;
}

.width-60 {
    width: 60px;
}

.width-30 {
    width: 30px;
}

.goto-btn {
    background: #4fb3ff url("../../../assets/img/icons/arrow-right-white.svg") no-repeat;
    background-size: 14px;
    background-position: center;
}

.row-padd {
    padding-bottom: 10px;
}

.org-inventory {
    position: relative;
    background: #fff;
    padding: 15px;
    margin-bottom: 30px;
    border-bottom: 4px solid #4fb3ff !important;
    border-radius: 5px;
    box-shadow: 0px 0px 3px #bbb;
    transition: 0.15s ease-in box-shadow;
}

.org-inventory h5 {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 600;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

html body .custom-btn {
    /* border: 1px solid #4FB3FF !important; */
    color: #4FB3FF !important
}

html body .custom-btn:hover,
html body .custom-btn:focus {
    background: #4FB3FF;
    color: #fff !important;
}