html body .order-module .custom-btn {
    width: auto;
    height: 35px;
    width: auto;
    padding: 0 20px;
    display: inline-block;
    background: #fff;
    border: 1px solid #400080 !important;
    border-radius: 5px;
    color: #400080 !important;
    outline: 0 !important;
    font-weight: 600;
    text-decoration: none !important;
    text-transform: uppercase;
    line-height: 34px;
}

html body .order-module .custom-btn:hover,
html body .order-module .custom-btn:focus {
    background: #400080 !important;
    color: #fff !important;
}

.entry-save.order-save {
    background: #400080 !important;
    border: 1px solid #400080 !important;
    color: #fff !important;
}

.entry-close.order-close {
    background: #fff !important;
    border: 1px solid #400080 !important;
    color: #400080 !important;
}

.page-link {
    color: #400080 !important;
  }
  
  .page-item.active .page-link {
    background-color: #400080 !important;
    border-color:#400080 !important;
    color: #fff !important;
  }

  .order-edit-btn{
    display: inline-block;
    width: 36px;
    height: 36px;
    background: url(../../../assets/img/edit-voilet.svg) no-repeat;
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: center;
    border: 1px solid #847bff00;
    border-radius: 5px;
    outline: 0;
    margin-right: 10px;
  }

  .order-delet-ebtn{
    display: inline-block;
    width: 36px;
    height: 36px;
    background: url(../../../assets/img/delete-voilet.svg) no-repeat;
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: center;
    border: 1px solid #847bff00;
    border-radius: 5px;
    outline: 0;
    margin-right: 10px;
  }