.logo {
    display: block;
    margin: 0 auto 30px;
}

.auth-wrapper {
    min-width: 400px;
    text-align: left;
    padding: 50px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 10px #888;
}

.auth-wrapper .btn {
    font-size: 0.85rem;
}

.auth-wrapper .btn-primary {
    background-color: #4FB3FF;
    border-color: #4FB3FF;
    width: 100%;
    text-align: center;
    outline: 0;
    padding: 0.7rem 0.75rem;
}

.auth-wrapper .btn-primary:hover {
    background-color: rgba(79, 179, 255, 0.8);
    border-color: rgba(79, 179, 255, 0.8);
}

.auth-wrapper .btn-primary:focus,
.auth-wrapper .btn-primary.focus,
.auth-wrapper .btn-primary:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 0 0.2rem rgba(79, 179, 255, 0.5);
    background-color: rgba(79, 179, 255, 0.8);
    border-color: rgba(79, 179, 255, 0.8);
}